import { type Localisation } from "./en";

export const spanish: Localisation = {
  StatusProvider: {
    "Screen is active but no token is present":
      "La pantalla está activa pero no hay token presente",
    "Network error": "Error de red",
  },
  AppProvider: {
    "Loading screen config": "Cargando configuración de pantalla",
  },
  InteractionScreen: {
    Start: "Comenzar",
  },
  SetupScreen: {
    "Scan the QR code on your Kairos app":
      "Escanee el código QR en su aplicación Kairos",
  },
  TemplateScreen: {
    "Powered by": "Desarrollado por",
    "Events page {{count}} of {{total}}": "Events page {{count}} of {{total}}",
    dateDayMonth: "eeee do LLLL",
  },
  ConfigNotFoundScreen: {
    "No screen config selected":
      "No se ha seleccionado ninguna configuración de pantalla",
    "Your device has been registered, but a configuration has not been selected":
      "Su dispositivo ha sido registrado, pero no se ha seleccionado una configuración",
    "You can create and customise your screens via the Kairos webapp":
      "Puede crear y personalizar sus pantallas a través de la aplicación web de Kairos",
    "We could not find a configuration for the given code. Please contact your Kairos administrator.":
      "No pudimos encontrar una configuración para el código proporcionado. Comuníquese con su administrador de Kairos.",
  },
  ErrorScreen: {
    "Something went wrong": "Algo salió mal",
    "Sorry for the inconvience, our engineers what been notified and will be working on a fix":
      "Lamentamos la molestia, nuestros ingenieros han sido notificados y trabajarán en una solución",
    "If this issue persists, please contact your account manager":
      "Si este problema persiste, por favor contacta a tu gerente de cuenta",
    Reload: "Recargar",
  },
  NextMatch: {
    day: "día",
    day_other: "días",
    hour: "hora",
    hour_other: "horas",
    minute: "minuto",
    minute_other: "minutos",
    second: "segundo",
    second_other: "segundos",
  },
};
