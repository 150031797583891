import React, {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { config } from "../helpers/config";
import { useTranslation } from "react-i18next";
import { api } from "../helpers/api";
import { errorReporting } from "../helpers/errorReporting";

type StatusResponse =
  | {
      status: "inactive";
    }
  | {
      status: "active";
      token?: string;
    };

type StatusResponseContext =
  | StatusResponse
  | {
      status: "error";
      reason: string;
    }
  | {
      status: "hard_coded";
      configCode: string;
    };

const StatusContext = createContext<
  StatusResponseContext & {
    throwError: (msg: string) => void;
    refreshStatus: () => void;
  }
>(null!);

export const StatusProvider = (props: PropsWithChildren) => {
  const [status, setStatus] = useState<StatusResponseContext>();
  const { t } = useTranslation("StatusProvider");
  const searchParams = new URLSearchParams(window.location.search);
  const refreshStatus = useCallback(() => {
    // if url has hard coded config id set status else continue
    const configCode = searchParams.get("code");

    if (configCode != null) {
      setStatus({ status: "hard_coded", configCode });
      return;
    }
    api()
      .get<StatusResponse | undefined>(`${config.deviceId}/status`, {
        headers: { "Kairos-Routing": "W" },
      })
      .then((r) => {
        if (r.data?.status === "inactive") {
          setStatus({ status: "inactive" });
          return;
        }

        const token = r.data?.token ?? localStorage.getItem("token");

        if (token != null) {
          localStorage.setItem("token", token);
          setStatus({ status: "active", token });
        } else {
          setStatus({
            status: "error",
            reason: t("Screen is active but no token is present"),
          });
        }
      })
      .catch((err) => {
        errorReporting.sendError(err);
        setStatus({ status: "error", reason: t("Network error") });
      });
  }, [t]);

  useEffect(() => {
    if (status == null) {
      refreshStatus();
      return;
    }

    if (status.status === "inactive") {
      const interval = setInterval(
        refreshStatus,
        config.setupScreen.pollingRate,
      );
      return () => {
        clearInterval(interval);
      };
    }
  }, [status, refreshStatus]);

  if (status == null) {
    return null;
  }

  return (
    <StatusContext.Provider
      {...props}
      value={{
        ...status,
        refreshStatus,
        throwError: (msg: string) => {
          setStatus({ status: "error", reason: msg });
        },
      }}
    />
  );
};

export const useStatus = () => useContext(StatusContext);

export const useSecureToken = (args?: { strict?: boolean }) => {
  const ctx = useContext(StatusContext);
  const token = ctx?.status === "active" ? ctx.token : undefined;
  if ((args?.strict ?? true) && token == null) {
    throw new Error("useSecureToken: token not ready");
  }

  return token;
};

export const useThrowError = () => {
  const ctx = useContext(StatusContext);
  if (ctx == null) {
    throw new Error("useThrowError: context not ready");
  }

  return ctx.throwError;
};

export const useRefreshStatus = () => {
  const ctx = useContext(StatusContext);
  if (ctx == null) {
    throw new Error("useRefreshStatus: context not ready");
  }

  return ctx.refreshStatus;
};
