import { useEffect, useState } from "react";
import { format } from "date-fns";
import { useSettings } from "../providers/ConfigProvider";
import { convertDateToTimezone } from "../helpers/timezone";

const dateFormat = "eee, do LLLL";
const timeFormat = "HH:mm";

export const useCurrentDate = () => {
  const deviceTimeZone = useSettings()?.deviceTimeZone;
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  useEffect(() => {
    const updateDate = () => {
      const newDate = convertDateToTimezone(new Date(), deviceTimeZone);
      setDate(format(newDate, dateFormat));
      setTime(format(newDate, timeFormat));
    };

    if (date.length === 0) {
      updateDate();
    }

    const timeout = setInterval(updateDate, 10000);
    return () => clearInterval(timeout);
  }, [deviceTimeZone, date]);

  return { date, time };
};
