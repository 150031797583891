import React, { useState } from "react";
import { makeStyles } from "../helpers/theme";
import {
  useAnnouncements,
  useComponent,
  useSettings,
} from "../providers/ConfigProvider";
import { AnnouncementsScroller } from "../components/AnnouncementsScroller";
import { useCurrentDate } from "../hooks/useCurrentDate";
import { MainContentCarosel } from "../components/MainContentCarosel";
import { CardsScroller } from "../components/CardsScroller";
import { Logo } from "../components/Logo";
import { Text } from "../components/Typography";
import { config } from "../helpers/config";
import { useTranslation } from "react-i18next";
import {
  EventsPaginated,
  type EventsPaginatedStatus,
} from "../components/EventsPaginated";
import { type Dimensions, useWindowDimensions } from "../providers/AppProvider";
import { Table } from "../components/Table";
import { NextMatch } from "../components/NextMatch";
import { Row } from "../components/Flex";

const poweredByHeight = 120;

const useLayoutStyles = makeStyles<{
  windowDimensions: Dimensions;
  announcementBarHeight: number;
}>()((theme, { windowDimensions, announcementBarHeight }) => ({
  table: {
    width: "100%",
    height: "100%",
    background: theme.background,
    "::after": {
      content: '""',
      display: "block",
      background: theme.accent,
      height: Math.round(windowDimensions.height / 4) + announcementBarHeight,
      width: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 0,
    },
  },
  annoucementBar: {
    height: config.announcementsScroller.height,
    position: "relative",
    zIndex: 1,
  },
  leftColumn: {
    width: Math.round((windowDimensions.width / 100) * 65),
    height: windowDimensions.height - announcementBarHeight,
    verticalAlign: "top",
    position: "relative",
    zIndex: 1,
    overflow: "hidden",
  },
  rightColumn: {
    width: Math.round((windowDimensions.width / 100) * 35),
    height: windowDimensions.height - announcementBarHeight,
    verticalAlign: "top",
    position: "relative",
    zIndex: 1,
    paddingLeft: theme.spacing.medium,
    paddingRight: theme.spacing.medium,
    overflow: "hidden",
  },
}));

export const LLayout = () => {
  const windowDimensions = useWindowDimensions();
  const hasAnnouncements = useAnnouncements().length > 0;

  const { classes } = useLayoutStyles({
    windowDimensions,
    announcementBarHeight: hasAnnouncements
      ? config.announcementsScroller.height
      : 0,
  });

  return (
    <Table className={classes.table}>
      {hasAnnouncements && (
        <tr>
          <td colSpan={2} className={classes.annoucementBar}>
            <AnnouncementsScroller />
          </td>
        </tr>
      )}
      <tr>
        <td className={classes.leftColumn}>
          <LeftColumnContent />
        </td>
        <td className={classes.rightColumn}>
          <RightColumnContent />
        </td>
      </tr>
    </Table>
  );
};

const useLeftColumnStyles = makeStyles<{
  hasCards: boolean;
  windowDimensions: Dimensions;
  announcementBarHeight: number;
}>()((theme, { hasCards, windowDimensions, announcementBarHeight }) => ({
  table: {
    width: "100%",
    height: windowDimensions.height - announcementBarHeight,
    paddingLeft: theme.spacing.medium,
    paddingBottom: theme.spacing.medium,
  },
  timeRow: {
    height: "20%",
    verticalAlign: "center",
    paddingLeft: theme.spacing.small,
  },
  time: {
    fontSize: theme.fontSize.xlarge,
    fontWeight: "bold",
    color: theme.accentText,
  },
  date: {
    fontSize: theme.fontSize.large,
    color: theme.accentText,
  },
  mediaRow: {
    height: hasCards ? "60%" : "80%",
    background: "rgba(255, 255, 255, 0.3)",
    padding: theme.spacing.small,
  },
  cardsRow: {
    height: "20%",
    background: "rgba(255, 255, 255, 0.3)",
    padding: theme.spacing.small,
    borderTopWidth: theme.spacing.medium,
    borderTopColor: theme.background,
    borderTopStyle: "solid",
  },
}));

const LeftColumnContent = () => {
  const { date, time } = useCurrentDate();
  const windowDimensions = useWindowDimensions();
  const hasAnnouncements = useAnnouncements().length > 0;
  const cardsComponent = useComponent("bottom_content_cards");
  const cards = cardsComponent?.cardText ?? [];
  const { classes } = useLeftColumnStyles({
    windowDimensions,
    hasCards: cards.length > 0,
    announcementBarHeight: hasAnnouncements
      ? config.announcementsScroller.height
      : 0,
  });

  return (
    <Table className={classes.table}>
      <tr>
        <td className={classes.timeRow}>
          <p className={classes.time}>{time}</p>
          <p className={classes.date}>{date}</p>
        </td>
      </tr>
      <tr>
        <td className={classes.mediaRow}>
          <MainContentCarosel />
        </td>
      </tr>
      {cards.length > 0 && (
        <tr>
          <td className={classes.cardsRow}>
            <CardsScroller cards={cards} />
          </td>
        </tr>
      )}
    </Table>
  );
};

const useRightColumnStyles = makeStyles<{
  windowDimensions: Dimensions;
  announcementBarHeight: number;
}>()((theme, { windowDimensions, announcementBarHeight }) => {
  const height = windowDimensions.height - announcementBarHeight;
  const logoContainerHeight = windowDimensions.height / 4;
  return {
    table: {
      width: "100%",
      height,
      background: "rgba(255, 255, 255, 0.3)",
    },
    logoContainer: {
      height: logoContainerHeight,
      textAlign: "center",
    },
    logo: {
      maxHeight: logoContainerHeight - theme.spacing.large * 2,
      maxWidth: windowDimensions.width / 4,
      fill: theme.accentText,
    },
    eventsContainer: {
      height: height - logoContainerHeight - poweredByHeight,
      paddingLeft: theme.spacing.medium,
      paddingRight: theme.spacing.medium,
    },
    poweredByWrapper: {
      height: poweredByHeight,
    },
    footerTable: {
      width: "100%",
    },
    poweredBy: {
      verticalAlign: "top",
      textAlign: "left",
      paddingLeft: theme.spacing.medium,
    },
    footerLogo: {
      verticalAlign: "bottom",
      textAlign: "left",
      paddingLeft: theme.spacing.medium,
    },
    currentEventPage: {
      verticalAlign: "top",
      textAlign: "right",
      paddingRight: theme.spacing.medium,
    },
    deviceName: {
      textAlign: "right",
      verticalAlign: "bottom",
      paddingRight: theme.spacing.medium,
    },
    footerText: {
      fontSize: theme.fontSize.small,
      color: theme.backgroundText,
      whiteSpace: "nowrap",
    },
    kairosLogo: {
      height: theme.spacing.large,
      fill: theme.backgroundText,
      marginBottom: -theme.spacing.xsmall,
      marginTop: theme.spacing.xsmall,
    },
    matchWrapper: {
      paddingLeft: theme.spacing.medium,
      paddingRight: theme.spacing.medium,
      alignItems: "center",
      justifyContent: "center",
    },
  };
});

const RightColumnContent = () => {
  const windowDimensions = useWindowDimensions();
  const hasAnnouncements = useAnnouncements().length > 0;
  const { classes } = useRightColumnStyles({
    windowDimensions,
    announcementBarHeight: hasAnnouncements
      ? config.announcementsScroller.height
      : 0,
  });
  const settings = useSettings();
  const { t } = useTranslation("TemplateScreen");
  const [eventsStatus, setEventsStatus] = useState<EventsPaginatedStatus>();
  const schedule = useComponent("right_schedule");
  if (schedule == null) {
    throw new Error("LLayout: schedule is empty");
  }
  return (
    <Table className={classes.table}>
      <tr>
        <td className={classes.logoContainer}>
          {schedule.nextMatch != null ? (
            <Row className={classes.matchWrapper}>
              <NextMatch
                nextMatch={schedule.nextMatch}
                timeZone={schedule.timeZone}
              />
            </Row>
          ) : settings?.logoUrl != null ? (
            <img alt="" src={settings.logoUrl} className={classes.logo} />
          ) : (
            <Logo className={classes.logo} />
          )}
        </td>
      </tr>
      <tr>
        <td className={classes.eventsContainer}>
          <EventsPaginated
            schedule={schedule}
            onChange={setEventsStatus}
            gap="medium"
          />
        </td>
      </tr>
      <tr>
        <td className={classes.poweredByWrapper}>
          <Table className={classes.footerTable}>
            <tr>
              <td className={classes.poweredBy}>
                <Text className={classes.footerText}>{t("Powered by")}</Text>
              </td>
              <td className={classes.currentEventPage}>
                <Text className={classes.footerText}>
                  {t("Events page {{count}} of {{total}}", {
                    count: eventsStatus?.current ?? 0,
                    total: eventsStatus?.total ?? 0,
                  })}
                </Text>
              </td>
            </tr>
            <tr>
              <td className={classes.footerLogo}>
                <Logo className={classes.kairosLogo} />
              </td>
              <td className={classes.deviceName}>
                <Text className={classes.footerText}>
                  <strong>{settings?.deviceName}</strong>
                </Text>
              </td>
            </tr>
          </Table>
        </td>
      </tr>
    </Table>
  );
};
