import * as Sentry from "@sentry/react";
import { config } from "./config";

const initialize = () => {
  if (config.isDevelopment) {
    return;
  }

  Sentry.init({
    dsn: "https://7d94758bbf98e41864a769ea15aeef06@o379212.ingest.sentry.io/4505787520253952",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["https://kairostech.tv"],
      }),
      new Sentry.Replay(),
    ],
    environment: config.isDevelopment ? "development" : "production",
  });

  Sentry.setUser({ deviceId: config.deviceId });
};

const sendError = (error: Error) => {
  if (config.isDevelopment) {
    // eslint-disable-next-line no-console
    return console.error(error);
  }
  Sentry.captureException(error);
};

const sendMessage = (message: string) => {
  if (config.isDevelopment) {
    // eslint-disable-next-line no-console
    return console.warn(message);
  }
  Sentry.captureMessage(message);
};

export const errorReporting = {
  initialize,
  sendError,
  sendMessage,
};
