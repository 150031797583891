import React, { type HTMLAttributes } from "react";

export const Table = (props: {
  className?: string;
  children?: HTMLAttributes<HTMLTableSectionElement>["children"];
}) => {
  return (
    <table className={props.className} cellPadding={0} cellSpacing={0}>
      <tbody>{props.children}</tbody>
    </table>
  );
};
