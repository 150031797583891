import React from "react";
import { Page, Spacer } from "../components/Flex";
import { useTranslation } from "react-i18next";
import { makeStyles } from "../helpers/theme";
import { useStatus } from "../providers/StatusProvider";

const useStyles = makeStyles()((theme) => ({
  page: {
    background: theme.background,
  },
  title: {
    color: theme.backgroundText,
    fontSize: theme.fontSize.medium,
    fontWeight: "bold",
  },
  text: {
    color: theme.backgroundText,
    fontSize: theme.fontSize.body,
    opacity: 0.8,
  },
}));

export const ConfigNotFoundScreen = () => {
  const { t } = useTranslation("ConfigNotFoundScreen");
  const { classes } = useStyles();
  const status = useStatus();
  return (
    <Page className={classes.page}>
      <Illustration />
      <Spacer size="large" />
      <p className={classes.title}>{t("No screen config selected")}</p>
      <Spacer size="medium" />
      <p className={classes.text}>
        {status.status === "hard_coded"
          ? t(
              "We could not find a configuration for the given code. Please contact your Kairos administrator.",
            )
          : t(
              "Your device has been registered, but a configuration has not been selected",
            )}
      </p>
      <Spacer size="small" />
      <p className={classes.text}>
        {t("You can create and customise your screens via the Kairos webapp")}
      </p>
    </Page>
  );
};

const useIllustrationStyles = makeStyles()((theme) => ({
  svg: {
    width: 300,
  },
  backgroundText: {
    fill: theme.backgroundText,
  },
  accent: {
    fill: theme.accent,
  },
}));

const Illustration = () => {
  const { classes } = useIllustrationStyles();
  return (
    <svg viewBox="0 0 651.25395 531.88962" className={classes.svg}>
      <path
        d="M898.9896,529.73661a215.88981,215.88981,0,0,1-10.08484,59.67914c-.1405.44836-.28772.89-.43506,1.33837H850.834c.04016-.40155.08032-.84991.12048-1.33837,2.50946-28.84919,16.97754-204.43348-.32122-234.46045C852.14567,357.39118,901.9072,438.06974,898.9896,529.73661Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#f0f0f0"
      />
      <path
        d="M896.1655,589.41575c-.31457.44836-.64245.89672-.977,1.33837H866.95493c.21418-.38147.46173-.82977.74951-1.33837,4.66431-8.41852,18.46985-33.5871,31.285-59.67914,13.77209-28.03943,26.40661-57.14289,25.34253-67.67609C924.66,464.43619,934.18943,536.85685,896.1655,589.41575Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#f0f0f0"
      />
      <path
        d="M349.36678,240.9553c-17.29876,30.027-2.83068,205.61126-.32122,234.46045.04016.48846.08032.93682.12048,1.33837H311.5303c-.14734-.44836-.29456-.89-.43506-1.33837a215.88981,215.88981,0,0,1-10.08484-59.67914C298.0928,324.06974,347.85433,243.39118,349.36678,240.9553Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#f0f0f0"
      />
      <path
        d="M275.668,348.06052c-1.06408,10.5332,11.57044,39.63666,25.34253,67.67609,12.81519,26.092,26.62073,51.26062,31.285,59.67914.28778.5086.53533.9569.74951,1.33837H304.81155c-.3346-.44165-.66248-.89-.97705-1.33837C265.81057,422.85685,275.34,350.43619,275.668,348.06052Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#f0f0f0"
      />
      <path
        d="M920.58356,217.047H289.15778a.91644.91644,0,0,1,0-1.83288H920.58356a.91644.91644,0,1,1,0,1.83288Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#cacaca"
      />
      <circle cx="35.41183" cy="10.08082" r="10.08082" fill="#3f3d56" />
      <circle cx="70.23647" cy="10.08082" r="10.08082" fill="#3f3d56" />
      <circle cx="105.06112" cy="10.08082" r="10.08082" fill="#3f3d56" />
      <path
        d="M899.71836,190.2213H874.97454a1.83288,1.83288,0,1,1,0-3.66575h24.74382a1.83288,1.83288,0,1,1,0,3.66575Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#3f3d56"
      />
      <path
        d="M899.71836,197.09459H874.97454a1.83288,1.83288,0,1,1,0-3.66575h24.74382a1.83288,1.83288,0,1,1,0,3.66575Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#3f3d56"
      />
      <path
        d="M899.71836,203.96787H874.97454a1.83288,1.83288,0,1,1,0-3.66575h24.74382a1.83288,1.83288,0,1,1,0,3.66575Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#3f3d56"
      />
      <path
        d="M888.2874,589.95491h-238a7.00786,7.00786,0,0,1-7-7v-310a7.00787,7.00787,0,0,1,7-7h238a7.00786,7.00786,0,0,1,7,7v310A7.00785,7.00785,0,0,1,888.2874,589.95491Zm-238-322a5.00589,5.00589,0,0,0-5,5v310a5.00589,5.00589,0,0,0,5,5h238a5.00588,5.00588,0,0,0,5-5v-310a5.00589,5.00589,0,0,0-5-5Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#e6e6e6"
      />
      <path
        d="M815.5,401.75412h-101a7.00817,7.00817,0,0,1-7-7v-79a7.00787,7.00787,0,0,1,7-7h101a7.00787,7.00787,0,0,1,7,7v79A7.00817,7.00817,0,0,1,815.5,401.75412Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#e6e6e6"
      />
      <path
        d="M815.2874,533.95491h-101a7.00818,7.00818,0,0,1-7-7v-79a7.00819,7.00819,0,0,1,7-7h101a7.00818,7.00818,0,0,1,7,7v79A7.00818,7.00818,0,0,1,815.2874,533.95491Zm-101-91a5.00589,5.00589,0,0,0-5,5v79a5.00589,5.00589,0,0,0,5,5h101a5.00588,5.00588,0,0,0,5-5v-79a5.00589,5.00589,0,0,0-5-5Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#3f3d56"
      />
      <path
        d="M843.2874,555.95491h-101a7.00818,7.00818,0,0,1-7-7v-79a7.00819,7.00819,0,0,1,7-7h101a7.00818,7.00818,0,0,1,7,7v79A7.00818,7.00818,0,0,1,843.2874,555.95491Z"
        transform="translate(-274.37302 -184.05519)"
        className={classes.accent}
      />
      <path
        d="M804.11852,532.824h-50.705a1.038,1.038,0,0,1-.18948-.015l23.98123-41.53876a1.67907,1.67907,0,0,1,2.92285,0L796.22237,519.146l.77106,1.33386Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#fff"
      />
      <polygon
        points="529.745 348.769 512.434 348.769 520.932 336.425 521.544 335.536 521.849 335.091 522.62 336.425 529.745 348.769"
        opacity="0.2"
      />
      <path
        d="M832.3507,532.824H788.49507l8.49836-12.34418.6116-.88922,11.07416-16.08685a2.24089,2.24089,0,0,1,3.32617-.19885,2.00551,2.00551,0,0,1,.15948.19885Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#fff"
      />
      <circle cx="555.9242" cy="299.54204" r="7.51151" fill="#fff" />
      <path
        d="M546.92126,589.86436h-238a7.00818,7.00818,0,0,1-7-7v-79a7.00818,7.00818,0,0,1,7-7h238a7.0085,7.0085,0,0,1,7,7v79A7.00849,7.00849,0,0,1,546.92126,589.86436Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#e6e6e6"
      />
      <path
        d="M545.92126,476.86436h-238a7.00818,7.00818,0,0,1-7-7v-193a7.00785,7.00785,0,0,1,7-7h238a7.00818,7.00818,0,0,1,7,7v193A7.00852,7.00852,0,0,1,545.92126,476.86436Zm-238-205a5.00589,5.00589,0,0,0-5,5v193a5.00588,5.00588,0,0,0,5,5h238a5.00589,5.00589,0,0,0,5-5v-193a5.00589,5.00589,0,0,0-5-5Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#3f3d56"
      />
      <path
        d="M584.92126,511.86436h-238a7.00849,7.00849,0,0,1-7-7v-193a7.00818,7.00818,0,0,1,7-7h238a7.00818,7.00818,0,0,1,7,7v193A7.00849,7.00849,0,0,1,584.92126,511.86436Z"
        transform="translate(-274.37302 -184.05519)"
        className={classes.accent}
      />
      <path
        d="M493.07434,464.36424H371.5689a2.47923,2.47923,0,0,1-.454-.036l57.4665-99.54022a4.02361,4.02361,0,0,1,7.00408,0l38.5672,66.79919,1.84766,3.19635Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#fff"
      />
      <polygon
        points="218.701 280.309 177.216 280.309 197.581 250.728 199.047 248.597 199.78 247.532 201.628 250.728 218.701 280.309"
        opacity="0.2"
      />
      <path
        d="M560.72766,464.36424H455.63543l20.36481-29.58066,1.46551-2.13089L504.003,394.1034a5.36975,5.36975,0,0,1,7.97058-.4765,4.79666,4.79666,0,0,1,.38214.4765Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#fff"
      />
      <circle cx="279.14791" cy="160.30906" r="18" fill="#fff" />
      <path
        d="M635.66948,501.792a7.9663,7.9663,0,0,1,5.32435-.84564l28.51971-26.651-4.734-23.76431,18.725,1.01547,1.674,28.84389-37.73034,29.38317a7.997,7.997,0,1,1-11.77867-7.98157Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#ffb8b8"
      />
      <polygon
        points="329.805 518.346 317.545 518.346 311.713 471.058 329.807 471.058 329.805 518.346"
        fill="#ffb6b6"
      />
      <path
        d="M607.30438,714.28526l-39.53076-.00146v-.5a15.38731,15.38731,0,0,1,15.38648-15.38623h.001l24.144.001Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#2f2e41"
      />
      <polygon
        points="421.805 518.346 409.545 518.346 403.713 471.058 421.807 471.058 421.805 518.346"
        fill="#ffb6b6"
      />
      <path
        d="M699.30438,714.28526l-39.53076-.00146v-.5a15.38731,15.38731,0,0,1,15.38648-15.38623h.001l24.144.001Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#2f2e41"
      />
      <path
        d="M673.81838,436.53682a9.99106,9.99106,0,0,0-12.75915-2.491h0a9.991,9.991,0,0,0-4.47473,12.0766l6.01165,16.6991,24.62348-9.3569Z"
        transform="translate(-274.37302 -184.05519)"
        className={classes.accent}
      />
      <path
        d="M671.60775,510.28444a20.33107,20.33107,0,0,0-3.59442-2.15582l-44.8147,6.89456.98493,28.07074c-21.67536,40.91211-36.00867,87.58533-43.82972,139.36871l28.07074,6.4021c12.56067-48.65893,26.82685-92.32336,44.8147-125.08709l18.961,123.90332,32.25574-.78608C700.20791,614.05638,696.9302,529.14159,671.60775,510.28444Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#2f2e41"
      />
      <circle cx="363.78587" cy="227.95895" r="17.4676" fill="#ffb8b8" />
      <path
        d="M676.57052,456.90022a22.68,22.68,0,0,0-12.49713-24.11935h0l-19.69861.985h0a21.7754,21.7754,0,0,0-14.52344,12.42808c-8.53839,19.98312-9.75451,45.53262-6.16022,74.73892l46.65821-11.099C663.95,495.42571,669.09371,476.60857,676.57052,456.90022Z"
        transform="translate(-274.37302 -184.05519)"
        className={classes.accent}
      />
      <path
        d="M616.5,407.75412v-.00006a17.99994,17.99994,0,0,1,18-17.99994h1.03247a18.40554,18.40554,0,0,1,11.46753-4h0a18.5,18.5,0,0,1,18.5,18.49994v23.50006c-11.96143-.298-25.50854,2.05982-40,6,3.65259-10.11767,6.08008-19.41931,4.42163-26Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#2f2e41"
      />
      <path
        d="M579.5,488.75412a7.96626,7.96626,0,0,1,5.07568,1.817l37.81787-9.66742,7.26807-23.11554,15.93335,9.88861-12.39258,26.09973-47.20825,7.63739A7.997,7.997,0,1,1,579.5,488.75412Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#ffb8b8"
      />
      <path
        d="M653.23464,450.785a9.991,9.991,0,0,0-5.27313-11.88257h0a9.991,9.991,0,0,0-12.46051,3.25638l-10.23676,14.49856,21.78223,14.81217Z"
        transform="translate(-274.37302 -184.05519)"
        className={classes.accent}
      />
      <path
        d="M750.50921,715.94481H506.2152a1.19069,1.19069,0,1,1,0-2.38137h244.294a1.19069,1.19069,0,1,1,0,2.38137Z"
        transform="translate(-274.37302 -184.05519)"
        fill="#cacaca"
      />
    </svg>
  );
};
