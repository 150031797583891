import axios from "axios";
import { config } from "./config";

export const api = (token?: string) =>
  axios.create({
    baseURL: config.apiBaseUrl,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token,
    },
    timeout: 15000,
  });
