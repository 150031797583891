export const english = {
  StatusProvider: {
    "Screen is active but no token is present":
      "Screen is active but no token is present",
    "Network error": "Network error",
  },
  AppProvider: {
    "Loading screen config": "Loading screen config",
  },
  InteractionScreen: {
    Start: "Start",
  },
  SetupScreen: {
    "Scan the QR code on your Kairos app":
      "Scan the QR code on your Kairos app",
  },
  TemplateScreen: {
    "Powered by": "Powered by",
    "Events page {{count}} of {{total}}": "Events page {{count}} of {{total}}",
    dateDayMonth: "eeee do LLLL",
  },
  ConfigNotFoundScreen: {
    "No screen config selected": "No screen config selected",
    "Your device has been registered, but a configuration has not been selected":
      "Your device has been registered, but a configuration has not been selected",
    "You can create and customise your screens via the Kairos webapp":
      "You can create and customise your screens via the Kairos webapp",
    "We could not find a configuration for the given code. Please contact your Kairos administrator.":
      "We could not find a configuration for the given code. Please contact your Kairos administrator.",
  },
  ErrorScreen: {
    "Something went wrong": "Something went wrong",
    "Sorry for the inconvience, our engineers what been notified and will be working on a fix":
      "Sorry for the inconvience, our engineers what been notified and will be working on a fix",
    "If this issue persists, please contact your account manager":
      "If this issue persists, please contact your account manager",
    Reload: "Reload",
  },
  NextMatch: {
    day: "day",
    day_other: "days",
    hour: "hour",
    hour_other: "hours",
    minute: "minute",
    minute_other: "minutes",
    second: "second",
    second_other: "seconds",
  },
};

export type Localisation = typeof english;
export type Namespace = keyof Localisation;
