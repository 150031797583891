import { createMakeAndWithStyles } from "tss-react";
import { useThemeOptions } from "../providers/ConfigProvider";
import { type Color, type NewType } from "../types";

const spacing = {
  none: 0,
  xsmall: 8,
  small: 12,
  medium: 25,
  large: 40,
};

export type Spacing = keyof typeof spacing;

export const useSpacing = () => {
  return spacing;
};

const iconSize = {
  small: 12,
  regular: 16,
  medium: 24,
  large: 40,
  larger: 80,
};

const fontSize = {
  small: 14,
  body: 16,
  medium: 18,
  large: 32,
  xlarge: 72,
};

export const { makeStyles, withStyles, useStyles } = createMakeAndWithStyles({
  useTheme: () => {
    const theme = useThemeOptions({ strict: false });

    return {
      spacing,
      iconSize,
      fontSize,
      ...theme,
    };
  },
});

const captureRgbValues = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
interface RBGColorComponents {
  r: number;
  g: number;
  b: number;
}

export const rgb = (hex: Color): RBGColorComponents => {
  const result = captureRgbValues.exec(hex?.toLowerCase())!;
  if (result == null) {
    throw new Error(`Hex: ${hex}`);
  }
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
};

type RBGAColor = NewType<string>;
export const toRgba = (hex: Color, opacity: number): RBGAColor => {
  const { r, g, b } = rgb(hex);
  return `rgba(${r},${g},${b},${opacity})` as RBGAColor;
};
