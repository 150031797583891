import { useEffect, useState } from "react";

export const useImageLoading = (src?: string) => {
  const [preLoaded, setPreLoaded] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (src != null && preLoaded.includes(src)) {
      setLoading(false);
      return;
    }

    setLoading(true);
    if (src != null) {
      const img = new Image();
      img.onload = () => {
        setLoading(false);
        setPreLoaded((a) => [...a, src]);
      };
      img.src = src;
    }
  }, [src]);

  return loading;
};
