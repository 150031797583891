import { type Localisation } from "./en";

export const dutch: Localisation = {
  StatusProvider: {
    "Screen is active but no token is present":
      "Het scherm is actief maar er is geen token aanwezig",
    "Network error": "Netwerkfout",
  },
  AppProvider: {
    "Loading screen config": "Laden schermconfiguratie",
  },
  InteractionScreen: {
    Start: "Starten",
  },
  SetupScreen: {
    "Scan the QR code on your Kairos app": "Scan de QR-code op je Kairos-app",
  },
  TemplateScreen: {
    "Powered by": "Aangedreven door",
    "Events page {{count}} of {{total}}": "Events page {{count}} of {{total}}",
    dateDayMonth: "eeee do LLLL",
  },
  ConfigNotFoundScreen: {
    "No screen config selected": "Geen schermconfiguratie geselecteerd",
    "Your device has been registered, but a configuration has not been selected":
      "Uw apparaat is geregistreerd, maar er is geen configuratie geselecteerd",
    "You can create and customise your screens via the Kairos webapp":
      "U kunt uw schermen maken en aanpassen via de Kairos-webapp",
    "We could not find a configuration for the given code. Please contact your Kairos administrator.":
      "We konden geen configuratie vinden voor de opgegeven code. Neem contact op met uw Kairos-beheerder.",
  },
  ErrorScreen: {
    "Something went wrong": "Er is iets misgegaan",
    "Sorry for the inconvience, our engineers what been notified and will be working on a fix":
      "Sorry voor het ongemak, onze ingenieurs zijn op de hoogte gebracht en zullen werken aan een oplossing",
    "If this issue persists, please contact your account manager":
      "Als dit probleem aanhoudt, neem dan contact op met uw accountmanager",
    Reload: "Vernieuwen",
  },
  NextMatch: {
    day: "dag",
    day_other: "dagen",
    hour: "uur",
    hour_other: "uur",
    minute: "minuut",
    minute_other: "minuten",
    second: "seconde",
    second_other: "seconden",
  },
};
