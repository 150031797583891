import React from "react";
import { Column, Row, Spacer } from "./Flex";
import { makeStyles } from "../helpers/theme";
import { Text } from "./Typography";
import { Logo } from "./Logo";
import {
  type NextMatchData,
  useSettings,
  useTemplate,
} from "../providers/ConfigProvider";
import { type Dimensions, useWindowDimensions } from "../providers/AppProvider";
import { type TimeZone } from "../types";
import { useCountdown } from "../hooks/useCountdown";
import { useTranslation } from "react-i18next";

const useLayoutStyles = makeStyles<{
  windowDimensions: Dimensions;
  lLayoutTemplate: boolean;
}>()((theme, { windowDimensions, lLayoutTemplate }) => {
  const logoContainerHeight = lLayoutTemplate
    ? Math.round(windowDimensions.height / 6)
    : Math.round(windowDimensions.height / 10);

  return {
    date: {
      fontSize: theme.fontSize.large,
      color: theme.backgroundText,
      fontWeight: 600,
      verticalAlign: "middle",
    },
    logo: {
      maxHeight:
        logoContainerHeight -
        (lLayoutTemplate ? theme.spacing.large * 2 : theme.spacing.small * 2),
      maxWidth: Math.round(windowDimensions.width / 4),
      fill: theme.accentText,
    },
    opponentDetails: {
      alignItems: "center",
      justifyContent: "center",
    },
    nextMatchWrapper: {
      justifyContent: "center",
      alignItems: "center",
    },
    nextMatchTitle: {
      fontWeight: "bold",
      fontSize: theme.fontSize.medium,
      color: theme.accentText,
      maxWidth: lLayoutTemplate
        ? Math.round(windowDimensions.width) / 4
        : undefined,
      verticalAlign: "middle",
      alignItems: "center",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    nextMatchDate: {
      fontSize: theme.fontSize.large,
      color: theme.accentText,
      verticalAlign: "middle",
      alignItems: "center",
      display: "flex",
      flexDirection: lLayoutTemplate ? "column" : "row",
    },
    nextMatchTime: {
      fontSize: theme.fontSize.xlarge,
      color: theme.accentText,
      fontWeight: "bold",
      verticalAlign: "middle",
      alignItems: "center",
    },
    multiDayViewDate: {
      fontWeight: "bold",
      fontSize: theme.fontSize.large,
      color: theme.accentText,
      verticalAlign: "middle",
    },
    multiDayViewRow: {
      alignItems: "center",
      justifyContent: "center",
      verticalAlign: "middle",
    },
    versus: {
      fontSize: theme.fontSize.large,
      color: theme.accentText,
      fontWeight: 600,
      verticalAlign: "middle",
    },
    timer: {
      paddingLeft: lLayoutTemplate ? undefined : theme.spacing.xsmall,
      fontSize: 40,
      maxHeight:
        logoContainerHeight -
        (lLayoutTemplate ? theme.spacing.large * 2 : theme.spacing.small * 2),
    },
  };
});

const MatchLogos = ({ nextMatch }: { nextMatch: NextMatchData }) => {
  const template = useTemplate();
  const settings = useSettings();
  const windowDimensions = useWindowDimensions();
  const { classes } = useLayoutStyles({
    windowDimensions,
    lLayoutTemplate: template === "l_layout_v1",
  });
  const homeLogoUrl = nextMatch.homeTeam
    ? settings?.logoUrl
    : nextMatch.opponentBadgeUrl;
  const awayLogoUrl = nextMatch.homeTeam
    ? nextMatch.opponentBadgeUrl
    : settings?.logoUrl;
  return (
    <Row className={classes.opponentDetails}>
      {nextMatch.opponentBadgeUrl !== null ? (
        <>
          <img
            alt=""
            src={settings?.usMatchDay ?? false ? awayLogoUrl : homeLogoUrl}
            className={classes.logo}
          />
          <Spacer size="medium" />
          <Text className={classes.versus}>
            {settings?.usMatchDay ?? false ? "@" : "V"}
          </Text>
          <Spacer size="medium" />
          <img
            alt=""
            src={settings?.usMatchDay ?? false ? homeLogoUrl : awayLogoUrl}
            className={classes.logo}
          />
        </>
      ) : settings?.logoUrl != null ? (
        <img alt="" src={settings?.logoUrl} className={classes.logo} />
      ) : (
        <Logo className={classes.logo} />
      )}
    </Row>
  );
};

export const NextMatch = ({
  nextMatch,
  timeZone,
}: {
  nextMatch: NextMatchData;
  timeZone: TimeZone | undefined;
}) => {
  const { t } = useTranslation("NextMatch");
  const template = useTemplate();
  const windowDimensions = useWindowDimensions();
  const { classes } = useLayoutStyles({
    windowDimensions,
    lLayoutTemplate: template === "l_layout_v1",
  });
  const { days, hours, minutes, seconds } = useCountdown(
    nextMatch.startsAt,
    timeZone,
  );

  return (
    <Row className={classes.nextMatchWrapper}>
      <div className={classes.nextMatchDate}>
        <MatchLogos nextMatch={nextMatch} />
        {nextMatch.opponentBadgeUrl == null && (
          <>
            <Spacer size={template === "l_layout_v1" ? "small" : "large"} />
            <Text className={classes.nextMatchTitle}>{nextMatch.title}</Text>
          </>
        )}
        <Spacer size={template === "l_layout_v1" ? "medium" : "large"} />
        {days + hours + minutes + seconds > 0 && (
          <Row className={classes.timer}>
            <CountdownNumber count={days} label={t("day", { count: days })} />
            <CountdownNumber
              count={hours}
              label={t("hour", { count: hours })}
            />
            <CountdownNumber
              count={minutes}
              label={t("minute", { count: minutes })}
            />
            <CountdownNumber
              count={seconds}
              label={t("second", { count: seconds })}
            />
          </Row>
        )}
      </div>
    </Row>
  );
};

const useCountdownTimeStyles = makeStyles()((theme) => {
  return {
    dateNumberText: {
      fontSize: theme.fontSize.small,
      color: theme.accentText,
    },
    dateText: {
      fontWeight: "bold",
      color: theme.backgroundText,
    },
    timerWrapper: {
      height: 60,
      width: 60,
      justifyContent: "center",
      alignItems: "center",
      background: theme.background,
      borderRadius: 4,
    },
    timeUnitColumn: {
      justifyContent: "center",
      alignItems: "center",
    },
  };
});

const CountdownNumber = ({
  label,
  count,
}: {
  label: string;
  count: number;
}) => {
  const { classes } = useCountdownTimeStyles();
  return (
    <>
      <Column className={classes.timeUnitColumn}>
        <Row className={classes.timerWrapper}>
          <Text className={classes.dateText}>{count}</Text>
        </Row>
        <Spacer size="xsmall" />
        <Text className={classes.dateNumberText}>{label}</Text>
      </Column>
      <Spacer size="small" />
    </>
  );
};
