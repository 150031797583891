import React, { useEffect, useRef } from "react";
import { flatMap } from "lodash";
import { Row } from "./Flex";
import { makeStyles } from "../helpers/theme";
import { useAnnouncements } from "../providers/ConfigProvider";
import MDEditor from "@uiw/react-md-editor";
import { config } from "../helpers/config";
import { useWindowDimensions, type Dimensions } from "../providers/AppProvider";

const { fps, speed, height } = config.announcementsScroller;

const useStyles = makeStyles<{ windowDimensions: Dimensions }>()(
  (theme, { windowDimensions }) => ({
    container: {
      backgroundColor: theme.announcementBar,
      overflowX: "auto",
      alignItems: "center",
      height,
      width: windowDimensions.width,
      zIndex: 1,
    },
    spacer: {
      width: theme.spacing.large * 2,
    },
    markdownContainer: {
      backgroundColor: theme.announcementBar,
      color: theme.announcementBarText,
      whiteSpace: "nowrap",
      fontSize: theme.fontSize.body,
    },
  }),
);

const dupeCount = 20;
export const AnnouncementsScroller = () => {
  const a = useAnnouncements();
  const windowDimensions = useWindowDimensions();
  const { classes } = useStyles({ windowDimensions });

  const rowRef = useRef<HTMLDivElement>(null);

  // duplicating the announcements to make sure they take up enough space for scroll to work
  const announcements = flatMap(Array(dupeCount).fill(a)) as string[];

  useEffect(() => {
    const el = rowRef.current;
    if (el == null) return;

    const resetPoint = el.scrollWidth / dupeCount;

    const interval = setInterval(() => {
      let left = el.scrollLeft + speed;
      if (left >= resetPoint) left = 0;
      el.scrollLeft = left;
    }, 1000 / fps);

    return () => {
      clearInterval(interval);
    };
  }, [rowRef, announcements]);

  if (announcements.length === 0) {
    return null;
  }

  return (
    <Row ref={rowRef} className={classes.container}>
      {announcements.map((announcement, i) => (
        <Row key={i}>
          <Row className={classes.spacer} />
          <MDEditor.Markdown
            source={announcement.replace(/\r?\n|\r/g, " ")}
            className={classes.markdownContainer}
          />
        </Row>
      ))}
    </Row>
  );
};
