import React from "react";
import { makeStyles } from "../helpers/theme";
import {
  useComponent,
  useSettings,
  useAnnouncements,
} from "../providers/ConfigProvider";
import { AnnouncementsScroller } from "../components/AnnouncementsScroller";
import { Logo } from "../components/Logo";
import { Text } from "../components/Typography";
import { config } from "../helpers/config";
import { EventsPaginated } from "../components/EventsPaginated";
import { type Dimensions, useWindowDimensions } from "../providers/AppProvider";
import { Table } from "../components/Table";
import { useCurrentDate } from "../hooks/useCurrentDate";
import { format } from "date-fns";
import { Row, Spacer } from "../components/Flex";
import { useTranslation } from "react-i18next";
import { NextMatch } from "../components/NextMatch";

const footerHeight = 110;

const useLayoutStyles = makeStyles<{
  windowDimensions: Dimensions;
  totalScheduleDays: number;
  announcementBarHeight: number;
}>()((
  theme,
  { windowDimensions, totalScheduleDays, announcementBarHeight },
) => {
  const logoContainerHeight = Math.round(windowDimensions.height / 10);
  const eventColumnWidth = Math.round(100 / totalScheduleDays);
  const eventColumnHeight =
    windowDimensions.height -
    logoContainerHeight -
    announcementBarHeight -
    footerHeight;

  return {
    table: {
      width: "100%",
      height: "100%",
      background: theme.background,
    },
    headerContainer: {
      background: theme.accent,
      height: logoContainerHeight,
      width: "100%",
      position: "relative",
      zIndex: 1,
      boxShadow: "2px 3px 11px 0 rgba(0, 0, 0, 0.49)",
    },
    eventColumn: {
      verticalAlign: "top",
      width: `${eventColumnWidth}%`,
      "&:nth-child(even)": {
        background: "rgba(0, 0, 0, 0.06)",
      },
    },
    eventWrapper: {
      color: theme.accent,
    },
    headerRow: {
      height: logoContainerHeight,
      paddingLeft: theme.spacing.small,
      paddingRight: theme.spacing.medium,
      textAlign: "start",
      alignItems: "center",
      justifyContent: "space-between",
    },
    announcementBar: {
      height: announcementBarHeight,
      position: "relative",
      zIndex: 1,
    },
    date: {
      fontSize: theme.fontSize.large,
      color: theme.accentText,
      fontWeight: "bold",
      verticalAlign: "middle",
    },
    logo: {
      maxHeight: logoContainerHeight - theme.spacing.small * 2,
      maxWidth: windowDimensions.width / 6,
      fill: theme.accentText,
    },
    deviceName: {
      verticalAlign: "middle",
      paddingTop: theme.spacing.medium,
      paddingLeft: theme.spacing.medium,
    },
    kairosLogo: {
      height: theme.spacing.large,
      fill: theme.backgroundText,
      marginTop: theme.spacing.xsmall,
    },
    footer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-start",
      boxSizing: "border-box",
      height: footerHeight,
      padding: theme.spacing.medium,
      paddingTop: 0,
    },
    footerRight: {
      alignItems: "flex-end",
    },
    footerText: {
      fontSize: theme.fontSize.small,
      color: theme.backgroundText,
      whiteSpace: "nowrap",
    },
    eventColumnTable: {
      width: "100%",
      height: eventColumnHeight,
    },
    eventsContainer: {
      height: "100%",
      paddingTop: theme.spacing.large,
      paddingBottom: theme.spacing.medium,
    },
    scheduleDate: {
      verticalAlign: "top",
      paddingBottom: theme.spacing.small,
      fontSize: theme.fontSize.medium,
      color: theme.backgroundText,
      textAlign: "center",
      fontWeight: 600,
    },
  };
});

export const MultiScheduleLayout = (props: {
  template: "multi_day_schedule" | "multi_column_schedule";
}) => {
  const windowDimensions = useWindowDimensions();
  const settings = useSettings();
  const schedule = useComponent(props.template);
  const { t } = useTranslation("TemplateScreen");
  if (schedule == null) {
    throw new Error("MultiScheduleLayout: schedule is empty");
  }
  const { date, time } = useCurrentDate();
  const hasAnnouncements = useAnnouncements().length > 0;
  const numberOfScheduledDays = schedule.events.length;
  const { classes } = useLayoutStyles({
    windowDimensions,
    announcementBarHeight: hasAnnouncements
      ? config.announcementsScroller.height
      : 0,
    totalScheduleDays: numberOfScheduledDays,
  });
  return (
    <Table className={classes.table}>
      {hasAnnouncements && (
        <tr>
          <td
            colSpan={numberOfScheduledDays}
            className={classes.announcementBar}
          >
            <AnnouncementsScroller />
          </td>
        </tr>
      )}

      <tr className={classes.headerContainer}>
        <td colSpan={numberOfScheduledDays}>
          <Row className={classes.headerRow}>
            {schedule.nextMatch != null ? (
              <Row>
                <Spacer size="small" />
                <NextMatch
                  nextMatch={schedule.nextMatch}
                  timeZone={schedule.timeZone}
                />
              </Row>
            ) : settings?.logoUrl != null ? (
              <img alt="" src={settings.logoUrl} className={classes.logo} />
            ) : (
              <Logo className={classes.logo} />
            )}
            <p className={classes.date}>
              {time} {date}
            </p>
          </Row>
        </td>
      </tr>

      <tr>
        {schedule.events.map((day, index) => (
          <td className={classes.eventColumn} key={index}>
            <Table className={classes.eventColumnTable}>
              <td className={classes.eventsContainer}>
                <p className={classes.scheduleDate}>
                  {day.type === "MultiDaySchedule"
                    ? format(new Date(day.date), t("dateDayMonth"))
                    : day.title}
                </p>
                <EventsPaginated
                  schedule={{
                    timeZone: schedule.timeZone,
                    events: day.events,
                    nextMatch: schedule.nextMatch,
                  }}
                />
              </td>
            </Table>

            {index === 0 && (
              <div className={classes.footer}>
                <Text className={classes.footerText}>{t("Powered by")}</Text>
                <Logo className={classes.kairosLogo} />
              </div>
            )}
            {index + 1 === schedule.events.length && (
              <div className={[classes.footer, classes.footerRight].join(" ")}>
                <Text className={classes.footerText}>
                  {settings?.deviceName}
                </Text>
              </div>
            )}
          </td>
        ))}
      </tr>
    </Table>
  );
};
