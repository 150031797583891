import { useEffect, useState } from "react";
import { type ISO8601DateTime, type TimeZone } from "../types";
import { convertDateToTimezone } from "../helpers/timezone";

const useCountdown = (
  targetDate: ISO8601DateTime,
  timeZone: TimeZone | undefined,
) => {
  const countDownDate = convertDateToTimezone(
    Date.parse(targetDate),
    timeZone,
  ).getTime();
  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime(),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

export { useCountdown };
