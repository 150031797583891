import { getTimezoneOffset } from "date-fns-tz";
import { type TimeZone } from "../types";
import { add, sub } from "date-fns";

export const userDefaultTimeZone = Intl.DateTimeFormat().resolvedOptions()
  .timeZone as TimeZone;

export const convertDateToTimezone = (
  date: Date | number,
  inTimeZone?: TimeZone,
  direction?: "add" | "subtract",
) => {
  const timeZone = inTimeZone ?? userDefaultTimeZone;
  const userTzOffset = getTimezoneOffset(userDefaultTimeZone, date);
  const eventTzOffset = getTimezoneOffset(timeZone, date);
  const tzDiff = (userTzOffset - eventTzOffset) / 1000;

  return direction === "add"
    ? add(date, { seconds: tzDiff })
    : sub(date, { seconds: tzDiff });
};
