import React, { useEffect, useRef, useState } from "react";
import { Column, Row } from "./Flex";
import { makeStyles } from "../helpers/theme";
import MDEditor from "@uiw/react-md-editor";
import { config } from "../helpers/config";
import { useAnnouncements } from "../providers/ConfigProvider";
import { defer } from "lodash";

const { startDelay, endDelay, fps, speed } = config.cardsScroller;

const useStyles = makeStyles<{ containerHeight?: number }>()((
  theme,
  { containerHeight },
) => {
  return {
    cardsContainer: {
      height: containerHeight ?? "100%",
      backgroundColor: "rgba(255, 255, 255, 0.3)",
    },
    card: {
      backgroundColor: theme.background,
      flexGrow: 1,
      borderRight: `5px solid ${theme.accent}`,
      color: theme.backgroundText,
      padding: theme.spacing.small,
      paddingLeft: theme.spacing.medium,
      width: "100%",
      height: "100%",
      overflowY: "auto",
      boxSizing: "border-box",
    },
    cardMarkdown: {
      backgroundColor: "transparent",
      color: theme.backgroundText,
      paddingTop: theme.spacing.small,
      paddingBottom: theme.spacing.small,
      fontSize: theme.fontSize.medium,
    },
  };
});

export const CardsScroller = ({ cards }: { cards: string[] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loopKey, setLoopKey] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState<number>();
  const { classes } = useStyles({ containerHeight });

  const hasCards = cards.length > 0;
  const hasAnnouncements = useAnnouncements().length > 0;

  // when cards or annoucements are added/removed - we need to recalculate the container dimensions
  useEffect(() => {
    setContainerHeight(undefined);
  }, [hasCards, hasAnnouncements]);

  useEffect(() => {
    const el = containerRef.current;
    if (el == null) return;

    if (containerHeight == null) {
      defer(() => {
        setContainerHeight(el.clientHeight);
      });
    }

    if (cards[currentIndex] == null && currentIndex > 0) {
      setCurrentIndex(0);
      return;
    }

    let interval: NodeJS.Timeout;
    let timeout = setTimeout(() => {
      interval = setInterval(() => {
        const top = el.scrollTop + speed;
        // reached the end of scroll (or didnt need to scroll)
        if (top >= el.scrollHeight - el.clientHeight) {
          timeout = setTimeout(() => {
            el.scrollTop = 0;
            setCurrentIndex((i) => {
              const nextIndex = i + 1;
              return nextIndex >= cards.length ? 0 : nextIndex;
            });
            // loop key required when there is a single card
            setLoopKey((k) => k + 1);
          }, endDelay);
          clearInterval(interval);
        } else {
          el.scrollTop = top;
        }
      }, 1000 / fps);
    }, startDelay);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [cards, currentIndex, loopKey, containerHeight]);

  return cards.length === 0 ? null : (
    <Row className={classes.cardsContainer}>
      <Column ref={containerRef} className={classes.card}>
        {containerHeight != null && (
          <MDEditor.Markdown
            source={cards[currentIndex]}
            className={classes.cardMarkdown}
          />
        )}
      </Column>
    </Row>
  );
};
