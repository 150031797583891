import React from "react";
import { Page } from "../components/Flex";
import { makeStyles } from "../helpers/theme";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles()((theme) => ({
  page: { background: theme.background },
  button: {
    padding: theme.spacing.medium,
    background: theme.accent,
    color: theme.accentText,
    border: 0,
    fontSize: theme.fontSize.medium,
    fontWeight: "bold",
    cursor: "pointer",
    transition: "0.3s ease",
    "&:hover": {
      padding: theme.spacing.large,
    },
  },
}));

export const InteractionScreen = (props: { onInteraction: () => void }) => {
  const { classes } = useStyles();
  const { t } = useTranslation("InteractionScreen");
  return (
    <Page className={classes.page}>
      <button className={classes.button} onClick={props.onInteraction}>
        {t("Start")}
      </button>
    </Page>
  );
};
