import React, {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { config } from "../helpers/config";
import {
  useRefreshStatus,
  useSecureToken,
  useStatus,
  useThrowError,
} from "./StatusProvider";
import {
  type ExtractByName,
  type Color,
  type ID,
  type ISO8601DateTime,
  type TimeZone,
  type Template,
} from "../types";
import { api } from "../helpers/api";
import { isEqual } from "lodash";
import { errorReporting } from "../helpers/errorReporting";
import { type AxiosError } from "axios";

export interface ScheduleConfigData {
  nextMatch: NextMatchData;
  events: ScheduleEvent[];
  timeZone?: TimeZone;
}

export type NextMatchData = ScheduleEvent & {
  homeTeam: boolean;
  opponentBadgeUrl: string;
};

export interface MultiDayScheduleConfigData {
  nextMatch: NextMatchData;
  events: MultiDaySchedule[];
  timeZone?: TimeZone;
  totalScheduleDays: number;
}

export interface MultiColumnScheduleConfigData {
  nextMatch: NextMatchData;
  events: MultiColumnSchedule[];
  timeZone?: TimeZone;
}

export interface CardsContentData {
  cardText: string[];
}

export type MediaContentData = ScreenMediaContent[];

interface ActiveConfigResponse {
  status: "active";
  settings: {
    deviceName: string;
    deviceTimeZone?: TimeZone;
    logoUrl?: string;
    usMatchDay: boolean;
  };
  theme: {
    background: Color;
    backgroundText: Color;
    accent: Color;
    accentText: Color;
    announcementBar: Color;
    announcementBarText: Color;
  };
  announcements: string[];
  template: Template;
  components: Array<
    | {
        name: "right_schedule";
        data: ScheduleConfigData;
      }
    | {
        name: "multi_day_schedule";
        data: MultiDayScheduleConfigData;
      }
    | {
        name: "multi_column_schedule";
        data: MultiColumnScheduleConfigData;
      }
    | {
        name: "bottom_content_cards";
        data: CardsContentData;
      }
    | {
        name: "media_content";
        data: MediaContentData;
      }
  >;
}

export type ConfigResponse =
  | {
      status: "config-not-found";
    }
  | ActiveConfigResponse;

export interface ScheduleEvent {
  id: ID;
  title: string;
  startsAt: ISO8601DateTime;
  endsAt: ISO8601DateTime;
  facility?: string;
  teams: string[];
}

export interface MultiDaySchedule {
  type: "MultiDaySchedule";
  date: ISO8601DateTime;
  events: ScheduleEvent[];
}

export interface MultiColumnSchedule {
  type: "MultiColumnSchedule";
  title: string;
  events: ScheduleEvent[];
}

export type ScreenMediaContent =
  | {
      type: "video";
      url: string;
    }
  | {
      type: "image";
      url: string;
      duration: number;
    }
  | {
      type: "youtube";
      youtubeId: string;
    };

type SettingsProviderContext =
  | {
      status: "config-not-found";
    }
  | {
      status: "active";
      themeOptions: ActiveConfigResponse["theme"];
      settings: ActiveConfigResponse["settings"];
      components: ActiveConfigResponse["components"];
      template: ActiveConfigResponse["template"];
      announcements: ActiveConfigResponse["announcements"];
    };

const defaultThemeOptions: ActiveConfigResponse["theme"] = {
  background: "#081B37" as Color,
  backgroundText: "#FFFFFF" as Color,
  accent: "#EF7D00" as Color,
  accentText: "#FFFFFF" as Color,
  announcementBar: "#CA0B00" as Color,
  announcementBarText: "#FFFFFF" as Color,
};

const Context = createContext<SettingsProviderContext | null>(null);

export const ConfigProvider = (props: PropsWithChildren) => {
  const secureToken = useSecureToken({ strict: false });
  const throwError = useThrowError();
  const refreshStatus = useRefreshStatus();
  const status = useStatus();
  const [data, setData] = useState<ConfigResponse>();

  useEffect(() => {
    if (secureToken == null && status.status !== "hard_coded") {
      return;
    }
    const refresh = () => {
      api(secureToken)
        .get<ConfigResponse>(
          status.status === "hard_coded"
            ? `${status.configCode}/code_config`
            : `${config.deviceId}/config`,
        )
        .then((r) => {
          if (!isEqual(r.data, data)) {
            setData(r.data);
          }
        })
        .catch((err: AxiosError) => {
          errorReporting.sendError(err);
          // don't want to retry on network error, as it would trigger an infinite loop
          if (err.code !== "ERR_NETWORK") {
            refreshStatus();
          }
        });
    };

    if (data == null) {
      refresh();
      return;
    }

    const interval = setInterval(refresh, config.pollingRate);
    return () => {
      clearInterval(interval);
    };
  }, [data, secureToken, throwError]);

  const context: SettingsProviderContext | null =
    data == null
      ? null
      : data.status === "config-not-found"
      ? {
          status: "config-not-found",
        }
      : {
          status: "active",
          themeOptions: data.theme,
          settings: data.settings,
          announcements: data.announcements,
          template: data.template,
          components: data.components,
        };

  return <Context.Provider {...props} value={context} />;
};

// null = loading
export const useHasConfig = () => {
  const ctx = useContext(Context);
  return ctx == null ? null : ctx.status === "active";
};

export const useThemeOptions = (args?: { strict?: boolean }) => {
  const ctx = useContext(Context);
  if ((args?.strict ?? true) && ctx == null) {
    throw new Error("useThemeOptions: context not ready");
  }

  return ctx?.status === "active"
    ? ctx?.themeOptions ?? defaultThemeOptions
    : defaultThemeOptions;
};

export const useSettings = (args?: { strict?: boolean }) => {
  const ctx = useContext(Context);
  if ((args?.strict ?? true) && ctx == null) {
    throw new Error("useSettings: context not ready");
  }

  return ctx?.status === "active" ? ctx?.settings : undefined;
};

export const useAnnouncements = (args?: { strict?: boolean }) => {
  const ctx = useContext(Context);
  if ((args?.strict ?? true) && ctx == null) {
    throw new Error("useAnnouncements: context not ready");
  }

  return ctx?.status === "active" ? ctx?.announcements ?? [] : [];
};

export const useTemplate = (args?: { strict?: boolean }) => {
  const ctx = useContext(Context);
  if ((args?.strict ?? true) && ctx == null) {
    throw new Error("useComponents: context not ready");
  }

  return ctx?.status === "active" ? ctx?.template : undefined;
};

export const useComponents = (args?: { strict?: boolean }) => {
  const ctx = useContext(Context);
  if ((args?.strict ?? true) && ctx == null) {
    throw new Error("useComponents: context not ready");
  }

  return ctx?.status === "active" ? ctx?.components : undefined;
};

export function useComponent<
  T extends ActiveConfigResponse["components"][number]["name"],
  DataType extends ExtractByName<
    T,
    ActiveConfigResponse["components"][number]
  >["data"],
>(name: T) {
  const ctx = useContext(Context);

  const components = ctx?.status === "active" ? ctx?.components ?? [] : [];
  return components.find((c) => c.name === name)?.data as DataType | undefined;
}
