import React, { type SVGAttributes } from "react";
export type IconProps = SVGAttributes<SVGElement>;

export const WorkspacesIcon = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M6,13c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S8.2,13,6,13z M12,3C9.8,3,8,4.8,8,7s1.8,4,4,4s4-1.8,4-4S14.2,3,12,3z M18,13 c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S20.2,13,18,13z" />
  </svg>
);

export const LocationIcon = (props: IconProps) => {
  return (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M32 5.333C21.68 5.333 13.333 13.68 13.333 24 13.333 38 32 58.667 32 58.667S50.667 38 50.667 24C50.667 13.68 42.32 5.333 32 5.333zm0 25.334A6.67 6.67 0 0125.333 24 6.669 6.669 0 0132 17.333 6.67 6.67 0 0138.667 24 6.67 6.67 0 0132 30.667z" />
    </svg>
  );
};
