import { type Localisation } from "./en";

export const italian: Localisation = {
  StatusProvider: {
    "Screen is active but no token is present":
      "Lo schermo è attivo ma nessun token è presente",
    "Network error": "Errore di rete",
  },
  AppProvider: {
    "Loading screen config": "Caricamento della configurazione dello schermo",
  },
  InteractionScreen: {
    Start: "Inizia",
  },
  SetupScreen: {
    "Scan the QR code on your Kairos app":
      "Scansiona il codice QR sulla tua applicazione Kairos",
  },
  TemplateScreen: {
    "Powered by": "Realizzato da",
    "Events page {{count}} of {{total}}": "Events page {{count}} of {{total}}",
    dateDayMonth: "eeee do LLLL",
  },
  ConfigNotFoundScreen: {
    "No screen config selected": "Nessuna configurazione schermo selezionata",
    "Your device has been registered, but a configuration has not been selected":
      "Il tuo dispositivo è stato registrato, ma non è stata selezionata una configurazione",
    "You can create and customise your screens via the Kairos webapp":
      "È possibile creare e personalizzare le schermate tramite l'app web Kairos",
    "We could not find a configuration for the given code. Please contact your Kairos administrator.":
      "Non siamo riusciti a trovare una configurazione per il codice indicato. Contatta il tuo amministratore Kairos.",
  },
  ErrorScreen: {
    "Something went wrong": "Qualcosa è andato storto",
    "Sorry for the inconvience, our engineers what been notified and will be working on a fix":
      "Ci scusiamo per l'inconveniente, i nostri ingegneri sono stati informati e stanno lavorando per risolvere il problema",
    "If this issue persists, please contact your account manager":
      "Se il problema persiste, contatta il tuo responsabile dell'account",
    Reload: "Ricarica",
  },
  NextMatch: {
    day: "giorno",
    day_other: "giorni",
    hour: "ora",
    hour_other: "ore",
    minute: "minuto",
    minute_other: "minuti",
    second: "secondo",
    second_other: "secondi",
  },
};
