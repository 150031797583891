import React, { type HTMLAttributes, forwardRef } from "react";
import { type Spacing, makeStyles } from "../helpers/theme";

const useStyles = makeStyles()(() => ({
  page: {
    width: "100vw",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    flexDirection: "row",
  },
}));

type FlexProps = HTMLAttributes<HTMLDivElement>;

export const Column = forwardRef<HTMLDivElement, FlexProps>(
  ({ className, ...props }, ref) => {
    const { classes } = useStyles();
    return (
      <div
        ref={ref}
        {...props}
        className={[classes.column, className].join(" ")}
      />
    );
  },
);

export const Row = forwardRef<HTMLDivElement, FlexProps>(
  ({ className, ...props }, ref) => {
    const { classes } = useStyles();
    return (
      <Column
        ref={ref}
        {...props}
        className={[classes.row, className].join(" ")}
      />
    );
  },
);

export const Page = forwardRef<HTMLDivElement, FlexProps>(
  ({ className, ...props }, ref) => {
    const { classes } = useStyles();
    return (
      <Column
        ref={ref}
        {...props}
        className={[classes.page, className].join(" ")}
      />
    );
  },
);

const useSpacerStyles = makeStyles<{ size: Spacing }>()((theme, { size }) => ({
  spacer: {
    height: theme.spacing[size],
    width: theme.spacing[size],
    maxWidth: theme.spacing[size],
    maxHeight: theme.spacing[size],
  },
}));

export const Spacer = (props: { size: Spacing }) => {
  const { classes } = useSpacerStyles({ size: props.size });
  return <Column className={classes.spacer} />;
};
