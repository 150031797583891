import React from "react";
import { useTemplate } from "../providers/ConfigProvider";
import { LLayout } from "../templates/LLayout";
import { MultiScheduleLayout } from "../templates/MultiScheduleLayout";
import { MediaOnly } from "../templates/MediaOnly";

export const TemplateScreen = () => {
  const template = useTemplate();
  switch (template) {
    case "l_layout_v1":
      return <LLayout />;
    case "media_only":
      return <MediaOnly />;
    case "multi_day_schedule":
    case "multi_column_schedule":
      return <MultiScheduleLayout template={template} />;
    default:
      throw new Error(`Template '${template ?? "undefined"}' not found`);
  }
};
