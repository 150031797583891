import { v4 as uuid } from "uuid";

const deviceId = localStorage.getItem("deviceId") ?? uuid();
localStorage.setItem("deviceId", deviceId);

const isDevelopment =
  (process.env.NODE_ENV ?? process.env.VITE_ENV) === "development";

export const config = {
  isDevelopment,
  // Silk is the amazon browser used by fire stick
  isSilk:
    /(?:; ([^;)]+) Build\/.*)?\bSilk\/([0-9._-]+)\b(.*\bMobile Safari\b)?/.exec(
      navigator.userAgent,
    ) != null,
  apiBaseUrl: isDevelopment
    ? "http://localhost:9000/v2/screens/"
    : "https://api.kairos-dev.com/v2/screens/",
  // this devices unique id
  deviceId,
  // how often to refresh events, theme changes etc
  pollingRate: 10000,
  // SetupScreen options
  setupScreen: {
    pollingRate: 5000,
    changeBackgroundRate: 10000,
    backgroundTransitionSpeed: 1000,
  },
  // CardsScroller options
  cardsScroller: {
    startDelay: 5000,
    endDelay: 5000,
    fps: 10, // lower fps = slower scroll
    speed: 1, // pixels to move per frame (no decimals for browser compatibility)
  },
  // EventsPaginated options
  eventsPaginated: {
    changePageRate: 10000,
  },
  // AnnoucementsScroller options
  announcementsScroller: {
    height: 50,
    fps: 24,
    speed: 1, // pixels to move per frame (no decimals for browser compatibility)
  },
};
