import React, { type HTMLAttributes } from "react";

export const Logo = (props: HTMLAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 3685.04 963.78"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M401.19 168.38L263.91 421.73l44.92-253.35H180.07L68.45 798.61l131.85-16.82 41.6-233.81 46.5 222.54 131.11-16.75-55.58-263.4 174.53-321.99H401.19z" />
      <path d="M954.9 739.27l-3 56.11h127.99l1.14-72.24-126.13 16.13z" />
      <path d="M977.33 324.3l-14.19 266.61-69.14 8.84zm-62.68-155.92L731.84 713.84l350.09-44.76 8.11-500.7z" />
      <path d="M712.94 770.22l-8.43 25.17h129.65l12.9-42.32-134.12 17.15z" />
      <path d="M1505.18 168.38l-80.88 456.95 131.8-16.86 77.88-440.09h-128.8z" />
      <path d="M1414.5 680.53l-20.31 114.85H1523l23.31-131.72-131.81 16.87zM2076.29 595.91l24.53 199.47h131.36l-27.45-215.89-128.44 16.42zM1904.32 617.91l-31.42 177.5h128.76l34.55-194.36-131.89 16.86z" />
      <path d="M2744.74 310.73c7.62-43.22 24.57-61 57.61-61 25.42 0 36.45 10.18 36.45 36.45a141 141 0 01-2.56 24.57l-24.12 137.15 131.72-16.82 12.73-72.08c5.07-27.93 7.62-52.5 7.62-74.55 0-89.81-44.92-124.55-152.52-124.55-129.65 0-176.23 43.22-202.51 190.63l-22.29 126.17 131.64-16.87z" />
      <path d="M2776.09 653c-4.22 25.42-11.88 61-55.91 61-26.27 0-38.15-11-38.15-37.3a133.2 133.2 0 012.55-23.72l24.25-138-131.68 16.82-12.9 72.86q-7.59 43.17-7.62 76.26c0 89.8 44.92 122.84 154.23 122.84 126.25 0 175.38-44.92 200.8-190.63l22.42-126.94-131.64 16.83z" />
      <path d="M3474.24 159.91c-116.92 0-167.76 49.14-192.33 190.63a291.34 291.34 0 00-4.06 37.83l130.31-16.66c0-7.62 1.7-18.69 11.83-75.37 6-33 18.65-46.63 43.22-46.63 19.5 0 28 9.33 28 28.83a114.25 114.25 0 01-1.71 17.8l-11.87 66.49L3609.25 346l1.42-8.15c3.36-20.35 5.92-40.66 5.92-57.61 0-82.22-44.93-120.33-142.35-120.33" />
      <path d="M3457.12 419.41l-173.4 22.17c12.65 41.32 46.62 68.68 116 90.29 35.6 11 44.92 22.87 44.92 42.37 0 5.11-1.7 16.09-14.39 89-6 33.93-22.06 50.84-47.48 50.84-20.31 0-30.48-11.84-30.48-33.9a143.18 143.18 0 011.7-16.94l8.51-50.56-131.07 16.75c-2.43 16.5-5.39 37.42-5.39 52.46 0 83 46.58 122 144.9 122 119.43 0 172-50 196.55-190.67 5.1-29.64 6.77-43.18 6.77-59.31 0-66.29-38.07-108-117.09-134.48" />
      <path d="M2159.3 360.72c-14.43 80.47-25.42 96.61-59.31 96.61h-38.16l34.75-199.15h38.11c22.91 0 32.23 7.63 32.23 35.6 0 12.69-1.7 33-7.62 66.94m-11.88-192.34h-163.55L1914.1 562.7l289.39-37c45.57-24.61 68.23-72.57 83.72-160.71 6-33.89 8.47-58.5 8.47-79.66 0-85.59-43.21-116.93-148.26-116.93" />
    </svg>
  );
};
