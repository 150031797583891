import React, { useEffect, useRef, useState } from "react";
import { type Spacing, makeStyles, useSpacing } from "../helpers/theme";
import {
  type ScheduleEvent,
  useAnnouncements,
  type ScheduleConfigData,
} from "../providers/ConfigProvider";
import {
  EventItem,
  hasLocationEventHeight,
  noLocationEventHeight,
} from "./EventItem";
import { last } from "lodash";
import { config } from "../helpers/config";
import { useWindowDimensions } from "../providers/AppProvider";
import { Table } from "./Table";

const useStyles = makeStyles()((_theme) => ({
  container: {
    height: "100%",
    width: "100%",
  },
}));

interface EventsPaginatedPage {
  events: ScheduleEvent[];
  height: number;
}

export interface EventsPaginatedStatus {
  current: number;
  total: number;
}

export const EventsPaginated = ({
  onChange,
  schedule,
  gap,
}: {
  onChange?: (status: EventsPaginatedStatus) => void;
  schedule: ScheduleConfigData;
  gap?: Spacing;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { classes } = useStyles();
  const spacing = useSpacing();
  const windowDimensions = useWindowDimensions();
  const hasAnnouncements = useAnnouncements().length > 0;

  const [pages, setPages] = useState<EventsPaginatedPage[]>([
    { events: [], height: 0 },
  ]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const currentPage = pages[currentPageIndex];

  const calcEventHeight = (event: ScheduleEvent) =>
    (event.facility != null ? hasLocationEventHeight : noLocationEventHeight) +
    spacing[gap ?? "none"];

  useEffect(() => {
    if (containerRef.current == null) {
      return;
    }

    const containerHeight = containerRef.current.clientHeight;

    const newPages: EventsPaginatedPage[] = [{ events: [], height: 0 }];
    (schedule?.events ?? []).forEach((event) => {
      const lastPage = last(newPages)!;
      const eventHeight = calcEventHeight(event);

      if (lastPage.height + eventHeight < containerHeight) {
        lastPage.events.push(event);
        lastPage.height += eventHeight;
      } else {
        newPages.push({ events: [event], height: eventHeight });
      }
    });

    setPages(newPages);
    setCurrentPageIndex(0);

    const interval = setInterval(() => {
      setCurrentPageIndex((i) => (i + 1 >= newPages.length ? 0 : i + 1));
    }, config.eventsPaginated.changePageRate);

    return () => {
      clearInterval(interval);
    };
  }, [schedule, containerRef, windowDimensions, hasAnnouncements]);

  useEffect(() => {
    onChange?.({ current: currentPageIndex + 1, total: pages.length });
  }, [currentPageIndex, pages, onChange]);

  return (
    <div ref={containerRef} className={classes.container}>
      <Table>
        {currentPage.events.map((event) => (
          <EventItem
            key={event.id}
            event={event}
            timeZone={schedule?.timeZone}
            width={containerRef.current?.clientWidth ?? 0}
            gap={gap}
          />
        ))}
      </Table>
    </div>
  );
};
