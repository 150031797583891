import { english, type Localisation } from "./en";

export const englishUS: Localisation = {
  ...english,
  TemplateScreen: {
    ...english.TemplateScreen,
    dateDayMonth: "eeee LLLL, do",
  },
  // Add overwrites here
};
