import React, { useEffect, useMemo, useState } from "react";
import { Column, Page, Row } from "../components/Flex";
import QRCode from "react-qr-code";
import { config } from "../helpers/config";
import { type Color } from "../types";
import { sample } from "lodash";
import { makeStyles } from "../helpers/theme";
import { Logo } from "../components/Logo";
import { Text } from "../components/Typography";
import { useTranslation } from "react-i18next";
import { useImageLoading } from "../hooks/useImageLoading";

const { changeBackgroundRate, backgroundTransitionSpeed } = config.setupScreen;

interface Background {
  url: string;
  color: Color;
}

const backgrounds: Background[] = [
  {
    url: "/assets/setup-background-1.jpg",
    color: "#3F5629" as Color,
  },
  {
    url: "/assets/setup-background-2.jpg",
    color: "#041724" as Color,
  },
  {
    url: "/assets/setup-background-3.jpg",
    color: "#D15539" as Color,
  },
  {
    url: "/assets/setup-background-4.jpg",
    color: "#C93F2C" as Color,
  },
];

const useStyles = makeStyles<{
  backgroundUrl: string;
  backgroundColor: Color;
  transitioning: boolean;
}>()((theme, { backgroundUrl, backgroundColor, transitioning }) => ({
  page: {
    backgroundSize: "cover",
    backgroundImage: `url(${backgroundUrl})`,
    backgroundPosition: "center",
    backgroundColor,
    flexGrow: 1,
    width: "100%",
    "&:after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor,
      zIndex: 0,
      transition: `${backgroundTransitionSpeed}ms ease`,
      opacity: transitioning ? 1 : 0,
    },
    "> *": {
      zIndex: 1,
    },
  },
  column: {
    flexGrow: 1,
    height: "100vh",
    width: "50vw",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    fill: "white",
    width: "30vw",
    transition: `${backgroundTransitionSpeed}ms ease`,
    filter: `drop-shadow(0 0 5px ${backgroundColor})`,
  },
  qrWrapper: {
    padding: theme.spacing.medium,
    background: "white",
  },
  divider: {
    width: 10,
    height: "50vh",
    background: "white",
    position: "absolute",
    left: "50vw",
    top: "50vh",
    marginLeft: -5,
    marginTop: "-25vh",
  },
  text: {
    color: "white",
    fontSize: theme.fontSize.medium,
    paddingLeft: theme.spacing.xsmall,
    transition: `${backgroundTransitionSpeed}ms ease`,
    textShadow: `0 0 10px ${backgroundColor}, 0 0 10px ${backgroundColor}`,
  },
}));

export const SetupScreen = () => {
  const firstBackground = useMemo(() => sample(backgrounds)!, backgrounds);
  const [backgroundUrl, setBackgroundUrl] = useState(firstBackground.url);
  const [backgroundColor, setBackgroundColor] = useState<Color>(
    firstBackground.color,
  );
  const [transitioning, setTransitioning] = useState(true);
  const backgroundLoading = useImageLoading(backgroundUrl);

  const { classes } = useStyles({
    backgroundUrl,
    backgroundColor,
    transitioning: transitioning || backgroundLoading,
  });
  const { t } = useTranslation("SetupScreen");

  useEffect(() => {
    // revert back to "not transitioning" after background change
    setTimeout(() => setTransitioning(false), backgroundTransitionSpeed);

    const nextBackground = sample(
      backgrounds.filter((b) => b.url !== backgroundUrl),
    )!;

    // pre-load the next image
    const img = new Image();
    img.src = nextBackground.url;

    let timeout = setTimeout(() => {
      setTransitioning(true);
      setBackgroundColor(nextBackground.color);
      timeout = setTimeout(
        () => setBackgroundUrl(nextBackground.url),
        backgroundTransitionSpeed,
      );
    }, changeBackgroundRate);

    return () => {
      clearTimeout(timeout);
    };
  }, [backgroundUrl]);

  return (
    <Page>
      <Row className={classes.page}>
        <Column className={classes.column}>
          <Column>
            <Logo className={classes.logo} />
            <Text className={classes.text}>
              {t("Scan the QR code on your Kairos app")}
            </Text>
          </Column>
        </Column>
        <Column className={classes.divider} />
        <Column className={classes.column}>
          <Column className={classes.qrWrapper}>
            <QRCode value={`kairos://screens/${config.deviceId}`} />
          </Column>
        </Column>
      </Row>
    </Page>
  );
};
