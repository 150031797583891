import React from "react";
import { type Spacing, makeStyles } from "../helpers/theme";
import { type ScheduleEvent, useTemplate } from "../providers/ConfigProvider";
import { type Template, type TimeZone } from "../types";
import { format } from "date-fns";
import { convertDateToTimezone } from "../helpers/timezone";
import { Text } from "./Typography";
import { Row, Spacer } from "./Flex";
import { LocationIcon, WorkspacesIcon } from "./Icons";

const lLayoutEventTimeWidth = 180;
const MultiDayLayoutEventTimeWidth = 150;

export const hasLocationEventHeight = 100;
export const noLocationEventHeight = 74;

const useEventItemStyles = makeStyles<{
  template?: Template;
  hasLocation: boolean;
  width: number;
}>()((theme, { hasLocation, width, template }) => {
  const multiColumnView =
    template === "multi_day_schedule" || template === "multi_column_schedule";
  const eventTimeWidth = multiColumnView
    ? MultiDayLayoutEventTimeWidth
    : lLayoutEventTimeWidth;
  return {
    eventTimeWrapper: {
      backgroundColor: multiColumnView ? undefined : theme.accent,
      alignItems: "center",
      justifyContent: "center",
      width: eventTimeWidth,
      // multi_day_schedule has border bottom of 1px
      height:
        (hasLocation ? hasLocationEventHeight : noLocationEventHeight) -
        (multiColumnView ? 1 : 0),
      textAlign: "center",
    },
    eventTime: {
      paddingLeft: multiColumnView ? theme.spacing.small : undefined,
      fontSize: theme.fontSize.body,
      fontWeight: "bold",
      color: multiColumnView ? theme.backgroundText : theme.accentText,
      whiteSpace: "nowrap",
    },
    eventInfoWrapper: {
      backgroundColor: multiColumnView ? undefined : theme.background,
      padding: theme.spacing.small,
      width: width - eventTimeWidth,
      justifyContent: "center",
    },
    eventRowBorder: {
      display: "block",
      width: `calc(100% - ${theme.spacing.small * 2})`,
      height: 0,
      margin: `0 ${theme.spacing.small}px`,
      borderBottom: `1px dashed ${theme.accent}`,
    },
    eventTitle: {
      fontSize: theme.fontSize.medium,
      color: theme.backgroundText,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: width - eventTimeWidth - theme.spacing.small * 2,
    },
    eventData: {
      fontSize: theme.fontSize.small,
      color: theme.backgroundText,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: width - eventTimeWidth - theme.spacing.small * 2,
    },
    chipIcon: {
      paddingRight: theme.spacing.xsmall,
      fill: theme.accent,
      width: theme.iconSize.small,
      height: theme.iconSize.small,
    },
    iconRow: {
      alignItems: "center",
    },
  };
});

export const EventItem = ({
  event,
  timeZone,
  width,
  gap,
}: {
  event: ScheduleEvent;
  timeZone?: TimeZone;
  width: number;
  gap?: Spacing;
}) => {
  const template = useTemplate();
  const { classes } = useEventItemStyles({
    hasLocation: event.facility != null,
    template,
    width,
  });

  return (
    <>
      <tr>
        <td colSpan={2}>
          <Spacer size={gap ?? "none"} />
        </td>
      </tr>
      <tr>
        <td className={classes.eventTimeWrapper}>
          <Text className={classes.eventTime}>
            {format(
              convertDateToTimezone(Date.parse(event.startsAt), timeZone),
              "HH:mm",
            )}{" "}
            -{" "}
            {format(
              convertDateToTimezone(Date.parse(event.endsAt), timeZone),
              "HH:mm",
            )}
          </Text>
        </td>
        <td className={classes.eventInfoWrapper}>
          <Text className={classes.eventTitle}>{event.title}</Text>
          {event.facility != null && (
            <>
              <Spacer size="xsmall" />
              <Row className={classes.iconRow}>
                <LocationIcon className={classes.chipIcon} />
                <Text className={classes.eventData}>{event.facility}</Text>
              </Row>
            </>
          )}
          <Spacer size="xsmall" />
          <Row className={classes.iconRow}>
            <WorkspacesIcon className={classes.chipIcon} />
            <Text className={classes.eventData}>{event.teams.join(", ")}</Text>
          </Row>
        </td>
      </tr>
      {template === "multi_day_schedule" && (
        <tr>
          <td colSpan={2}>
            <span className={classes.eventRowBorder} />
          </td>
        </tr>
      )}
    </>
  );
};
