import { mapValues, padEnd } from "lodash";
import { english, type Localisation, type Namespace } from "./en";

const replacements: Record<string, string> = {
  a: "å",
  c: "ç",
  e: "é",
  f: "ƒ",
  i: "î",
  o: "ø",
  u: "ü",
};

const disabledNamespaces = new Set<Namespace>([] as const);

const interpolationNamespaces = new Set<Namespace>([] as const);

export const englishDevelopment = mapValues(
  english,
  (namespace, spaceName: Namespace) => {
    if (disabledNamespaces.has(spaceName)) {
      return namespace;
    }
    return mapValues(namespace, (value: string) => {
      const isInterpolated = value.includes("{{");
      return `[${padEnd(
        isInterpolated || interpolationNamespaces.has(spaceName)
          ? value
          : value
              .split("")
              .map((character) => {
                return replacements[character] ?? character;
              })
              .join(""),
        Math.ceil(value.length * 1.2),
        "_",
      )}]`;
    });
  },
) as unknown as Localisation;
