import React from "react";
import { makeStyles } from "../helpers/theme";
import { useAnnouncements } from "../providers/ConfigProvider";
import { AnnouncementsScroller } from "../components/AnnouncementsScroller";
import { MainContentCarosel } from "../components/MainContentCarosel";
import { config } from "../helpers/config";
import { type Dimensions, useWindowDimensions } from "../providers/AppProvider";
import { Table } from "../components/Table";
import { Logo } from "../components/Logo";
import { Text } from "../components/Typography";
import { useTranslation } from "react-i18next";

const useLayoutStyles = makeStyles<{
  windowDimensions: Dimensions;
  announcementBarHeight: number;
}>()((theme, { windowDimensions, announcementBarHeight }) => ({
  table: {
    width: "100%",
    height: "100%",
    background: theme.background,
  },
  announcementBar: {
    height: config.announcementsScroller.height,
    position: "relative",
    zIndex: 1,
  },
  mediaContainer: {
    height: windowDimensions.height - announcementBarHeight,
    width: "100%",
    verticalAlign: "top",
    position: "relative",
    zIndex: 1,
    overflow: "hidden",
  },
  footerText: {
    fontSize: theme.fontSize.small,
    color: theme.backgroundText,
    whiteSpace: "nowrap",
  },
  kairosLogo: {
    height: theme.spacing.large,
    fill: theme.backgroundText,
    marginBottom: -theme.spacing.xsmall,
    marginTop: theme.spacing.xsmall,
  },
  poweredBy: {
    paddingLeft: theme.spacing.medium,
    paddingBottom: theme.spacing.small,
    position: "absolute",
    left: 0,
    bottom: 0,
    zIndex: 99,
  },
}));

export const MediaOnly = () => {
  const windowDimensions = useWindowDimensions();
  const hasAnnouncements = useAnnouncements().length > 0;
  const { t } = useTranslation("TemplateScreen");

  const { classes } = useLayoutStyles({
    windowDimensions,
    announcementBarHeight: hasAnnouncements
      ? config.announcementsScroller.height
      : 0,
  });

  return (
    <Table className={classes.table}>
      {hasAnnouncements && (
        <tr>
          <td colSpan={2} className={classes.announcementBar}>
            <AnnouncementsScroller />
          </td>
        </tr>
      )}
      <tr>
        <td className={classes.mediaContainer}>
          <MainContentCarosel />
        </td>
        <td className={classes.poweredBy}>
          <Text className={classes.footerText}>{t("Powered by")}</Text>
          <Logo className={classes.kairosLogo} />
        </td>
      </tr>
    </Table>
  );
};
