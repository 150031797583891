import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ConfigProvider } from "./providers/ConfigProvider";
import { StatusProvider } from "./providers/StatusProvider";
import { TemplateScreen } from "./screens/TemplateScreen";
import { AppProvider } from "./providers/AppProvider";
import { initLocalisation } from "./helpers/localise";
import { errorReporting } from "./helpers/errorReporting";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorScreen } from "./screens/ErrorScreen";

initLocalisation();
errorReporting.initialize();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <ErrorBoundary fallback={<ErrorScreen />}>
    <StatusProvider>
      <ConfigProvider>
        <AppProvider>
          <TemplateScreen />
        </AppProvider>
      </ConfigProvider>
    </StatusProvider>
  </ErrorBoundary>,
);
