import { type Localisation } from "./en";

export const french: Localisation = {
  StatusProvider: {
    "Screen is active but no token is present":
      "L'écran est actif mais aucun jeton n'est présent",
    "Network error": "Erreur réseau",
  },
  AppProvider: {
    "Loading screen config": "Chargement de la configuration de l'écran",
  },
  InteractionScreen: {
    Start: "Démarrer",
  },
  SetupScreen: {
    "Scan the QR code on your Kairos app":
      "Scannez le code QR sur votre application Kairos",
  },
  TemplateScreen: {
    "Powered by": "Propulsé par",
    "Events page {{count}} of {{total}}": "Events page {{count}} of {{total}}",
    dateDayMonth: "eeee do LLLL",
  },
  ConfigNotFoundScreen: {
    "No screen config selected": "Aucune configuration d'écran sélectionnée",
    "Your device has been registered, but a configuration has not been selected":
      "Votre appareil a été enregistré, mais aucune configuration n'a été sélectionnée",
    "You can create and customise your screens via the Kairos webapp":
      "Vous pouvez créer et personnaliser vos écrans via l'application web Kairos",
    "We could not find a configuration for the given code. Please contact your Kairos administrator.":
      "Nous n'avons pas trouvé de configuration pour le code donné. Veuillez contacter votre administrateur Kairos.",
  },
  ErrorScreen: {
    "Something went wrong": "Quelque chose s'est mal passé",
    "Sorry for the inconvience, our engineers what been notified and will be working on a fix":
      "Désolé pour le désagrément, nos ingénieurs ont été informés et travailleront sur une solution",
    "If this issue persists, please contact your account manager":
      "Si ce problème persiste, veuillez contacter votre gestionnaire de compte",
    Reload: "Recharger",
  },
  NextMatch: {
    day: "jour",
    day_other: "jours",
    hour: "heure",
    hour_other: "heures",
    minute: "minute",
    minute_other: "minutes",
    second: "seconde",
    second_other: "secondes",
  },
};
