import React, { useEffect, useRef, useState } from "react";
import YouTubePlayer from "youtube-player";
import { Column, Row } from "./Flex";
import { makeStyles, toRgba } from "../helpers/theme";
import { useAnnouncements, useComponent } from "../providers/ConfigProvider";
import { useImageLoading } from "../hooks/useImageLoading";
import { LoadingSpinner } from "../screens/LoadingScreen";
import { Text } from "./Typography";
import { type Dimensions } from "../providers/AppProvider";
import { errorReporting } from "../helpers/errorReporting";

const useStyles = makeStyles()((theme) => ({
  mediaWrapper: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  slideNumber: {
    position: "absolute",
    right: 0,
    bottom: 0,
    backgroundColor: toRgba(theme.background, 0.7),
    padding: theme.spacing.small,
    pointerEvents: "none",
  },
  slideNumberText: {
    color: theme.backgroundText,
    fontSize: theme.fontSize.small,
  },
}));

export const MainContentCarosel = () => {
  const { classes } = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);
  const data = useComponent("media_content") ?? [];

  const currentMedia = data[activeIndex];

  const goToNextScreen = () => {
    setActiveIndex((i) => {
      const next = i + 1;
      return next >= data.length ? 0 : next;
    });
  };

  // When data changes, ensure the active index is still valid
  useEffect(() => {
    if (data.length <= activeIndex) {
      setActiveIndex(0);
    }
  }, [data, activeIndex]);

  return (
    <Column className={classes.mediaWrapper}>
      {currentMedia != null &&
        (currentMedia.type === "image" ? (
          <Image
            src={currentMedia.url}
            duration={currentMedia.duration * 1000}
            onComplete={goToNextScreen}
          />
        ) : currentMedia.type === "video" ? (
          <Video
            src={currentMedia.url}
            onComplete={goToNextScreen}
            loop={data.length === 1}
          />
        ) : currentMedia.type === "youtube" ? (
          <Youtube
            videoId={currentMedia.youtubeId}
            onComplete={goToNextScreen}
            loop={data.length === 1}
          />
        ) : null)}
      {data.length > 1 && (
        <Row className={classes.slideNumber}>
          <Text className={classes.slideNumberText}>
            {activeIndex + 1} / {data.length}
          </Text>
        </Row>
      )}
    </Column>
  );
};

const useImageStyles = makeStyles<{
  imgSrc?: string;
}>()((theme, { imgSrc }) => ({
  img: {
    width: "100%",
    height: "100%",
    backgroundImage: imgSrc == null ? undefined : `url(${imgSrc})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: theme.background,
    backgroundSize: "contain",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  loadingWrapper: {
    position: "absolute",
    left: 0,
    bottom: 0,
  },
  loadingSpinner: {
    transform: "scale(0.3)",
  },
}));

const Image = ({
  src,
  onComplete,
  duration,
}: {
  src: string;
  onComplete: () => void;
  duration: number;
}) => {
  useEffect(() => {
    const timeout = setTimeout(() => onComplete(), duration);
    return () => clearTimeout(timeout);
  }, [onComplete, duration]);

  const loading = useImageLoading(src);
  const { classes } = useImageStyles({
    imgSrc: loading ? undefined : src,
  });

  return (
    <div className={classes.img}>
      {loading && (
        <div className={classes.loadingWrapper}>
          <LoadingSpinner className={classes.loadingSpinner} />
        </div>
      )}
    </div>
  );
};

const useVideoStyles = makeStyles<{ dimensions?: Dimensions }>()(
  (_theme, { dimensions }) => ({
    videoWrapper: {
      width: "100%",
      height: "100%",
    },
    video: {
      width: "100%",
      height: "100%",
      maxWidth: dimensions?.width,
      maxHeight: dimensions?.height,
      backgroundColor: "black",
    },
    videoInline: {
      width: dimensions == null ? 0 : "100%",
      height: dimensions == null ? 0 : "100%",
      // odd issue with inline videos adding extra space below video
      marginBottom: -5,
    },
  }),
);

const Video = ({
  src,
  onComplete,
  loop,
}: {
  src: string;
  onComplete: () => void;
  loop: boolean;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [dimensions, setDimensions] = useState<Dimensions>();
  const { classes } = useVideoStyles({ dimensions });

  const hasCards =
    (useComponent("bottom_content_cards")?.cardText ?? []).length > 0;
  const hasAnnouncements = useAnnouncements().length > 0;

  // when cards or annoucements are added/removed - we need to recalculate the container dimensions
  useEffect(() => {
    setDimensions(undefined);
  }, [hasCards, hasAnnouncements]);

  useEffect(() => {
    if (containerRef.current != null && dimensions == null) {
      setDimensions({
        width: Math.round(containerRef.current.clientWidth),
        height: Math.round(containerRef.current.clientHeight),
      });
      return;
    }

    if (videoRef.current != null) {
      videoRef.current.currentTime = 0;
      videoRef.current.play().catch((err) => {
        if ((err.message as string).includes("interact")) {
          // some browsers will not allow videos to autoplay without
          // an interaction - this catch prevents the page from erroring
        } else {
          throw new Error(err.message);
        }
      });
    }
  }, [dimensions, src]);

  return (
    <div ref={containerRef} className={classes.videoWrapper}>
      <video
        ref={videoRef}
        src={src}
        controls
        onError={() => {
          errorReporting.sendMessage(`Video playback error: ${src}`);
          if (!loop) {
            onComplete();
          }
        }}
        className={[classes.video, classes.videoInline].join(" ")}
        onEnded={() => {
          if (loop && videoRef.current != null) {
            videoRef.current.currentTime = 0;
            videoRef.current.play();
          } else {
            onComplete();
          }
        }}
      />
    </div>
  );
};

const Youtube = ({
  videoId,
  onComplete,
  loop,
}: {
  videoId: string;
  onComplete: () => void;
  loop: boolean;
}) => {
  const { classes } = useVideoStyles({ dimensions: undefined });
  const [loopKey, setLoopKey] = useState(0);
  const playerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (playerRef.current == null) {
      return;
    }

    const player = YouTubePlayer(playerRef.current, {
      videoId,
      playerVars: {
        enablejsapi: 1,
      },
    });

    player.playVideo();

    player.on("stateChange", (event) => {
      // on video ended
      if (event.data === 0 && loop) {
        setLoopKey((k) => k + 1);
      } else if (event.data === 0) {
        onComplete();
      }
    });

    return () => {
      player.destroy();
    };
  }, [videoId, playerRef, loop, loopKey]);

  return (
    <div className={classes.video}>
      <div ref={playerRef} className={classes.video} />
    </div>
  );
};
