import React, {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { LoadingScreen } from "../screens/LoadingScreen";
import { InteractionScreen } from "../screens/InteractionScreen";
import { useStatus } from "./StatusProvider";
import { ErrorScreen } from "../screens/ErrorScreen";
import { SetupScreen } from "../screens/SetupScreen";
import { useTranslation } from "react-i18next";
import { useHasConfig, useTemplate } from "./ConfigProvider";
import { ConfigNotFoundScreen } from "../screens/ConfigNotFoundScreen";
import { config } from "../helpers/config";

type AppProviderProps = Omit<PropsWithChildren, "children"> & {
  children: JSX.Element;
};

export interface Dimensions {
  width: number;
  height: number;
}

const AppContext = createContext<{
  windowDimensions: Dimensions;
}>(null!);

export const AppProvider = (props: AppProviderProps) => {
  const searchParams = new URLSearchParams(window.location.search);
  const status = useStatus();
  const hasConfig = useHasConfig();
  const template = useTemplate({ strict: false });
  const { t } = useTranslation("AppProvider");

  const [interacted, setInteracted] = useState(
    config.isSilk ? false : searchParams.get("autoplay") == null,
  );

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // window dimensions
    const updateDimensions = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", updateDimensions);

    // set zoom level
    const zoom = searchParams.get("zoom") ?? (config.isSilk ? "0.5" : "1");
    document
      ?.querySelector(`meta[name="viewport"]`)
      ?.setAttribute(
        "content",
        `width=device-width, initial-scale=${zoom}, minimum-scale=${zoom}, maximum-scale=${zoom}`,
      );

    // call wakeLock to prevent screen going to sleep
    const wakeLockInterval = setInterval(() => {
      if (navigator.wakeLock != null) {
        navigator.wakeLock.request("screen").catch(() => {});
      }
    }, 60000);

    return () => {
      window.removeEventListener("resize", updateDimensions);
      clearInterval(wakeLockInterval);
    };
  }, [searchParams, config]);

  if (status.status === "error") {
    return <ErrorScreen message={status.reason} />;
  }

  if (status.status === "inactive") {
    return <SetupScreen />;
  }

  if (hasConfig == null) {
    return <LoadingScreen />;
  }

  if (!hasConfig) {
    return <ConfigNotFoundScreen />;
  }

  if (status.status === "hard_coded" && template == null) {
    return <ConfigNotFoundScreen />;
  }

  if (!interacted) {
    return (
      <InteractionScreen
        onInteraction={() => {
          setInteracted(true);
        }}
      />
    );
  }

  if (template == null) {
    return <LoadingScreen message={t("Loading screen config")} />;
  }

  return <AppContext.Provider {...props} value={{ windowDimensions }} />;
};

export const useWindowDimensions = () => {
  const ctx = useContext(AppContext);
  if (ctx == null) {
    throw new Error("useWindowDimensions: context not ready");
  }

  return ctx.windowDimensions;
};
