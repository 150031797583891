import React, { type HTMLAttributes } from "react";
import { Page, Spacer } from "../components/Flex";
import { Text } from "../components/Typography";
import { makeStyles } from "../helpers/theme";

const useLoadingStyles = makeStyles<{ spinnerColor?: string }>()(
  (theme, { spinnerColor }) => ({
    page: {
      backgroundColor: theme.background,
    },
    text: {
      color: theme.backgroundText,
    },
    scaledSpinner: {
      transform: "scale(0.7)",
    },
    spinner: {
      display: "inline-block",
      position: "relative",
      width: 48,
      height: 48,
      "> div": {
        boxSizing: "border-box",
        display: "block",
        position: "absolute",
        width: 64,
        height: 64,
        borderWidth: 8,
        borderStyle: "solid",
        borderRadius: "50%",
        animation: "spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite",
        borderColor: `${
          spinnerColor ?? theme.backgroundText
        } transparent transparent transparent`,
      },
      "> div:nth-child(1)": {
        animationDelay: "-0.45s",
      },
      "> div:nth-child(2)": {
        animationDelay: "-0.3s",
      },
      "> div:nth-child(3)": {
        animationDelay: "-0.15s",
      },
    },
  }),
);

export const LoadingScreen = (props: { message?: string }) => {
  const { classes } = useLoadingStyles({});
  return (
    <Page className={classes.page}>
      <LoadingSpinner className={classes.scaledSpinner} />
      <Spacer size="large" />
      <Text className={classes.text}>{props.message}</Text>
    </Page>
  );
};

export const LoadingSpinner = ({
  color,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement> & { color?: string }) => {
  const { classes } = useLoadingStyles({ spinnerColor: color });

  return (
    <div className={[className, classes.spinner].join(" ")} {...props}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};
