import i18n, { type KeyPrefix } from "i18next";
import { initReactI18next } from "react-i18next";
import {
  type Localisation,
  type Namespace,
  english,
} from "../localisations/en";
import { englishUS } from "../localisations/enUS";
import { spanish } from "../localisations/es";
import { french } from "../localisations/fr";
import { dutch } from "../localisations/nl";
import { italian } from "../localisations/it";
import { first } from "lodash";
import { config } from "./config";
import { englishDevelopment } from "../localisations/enDev";

const resources = {
  en: config.isDevelopment ? englishDevelopment : english,
  "en-GB": config.isDevelopment ? englishDevelopment : english,
  "en-US": englishUS,
  es: spanish,
  "es-ES": spanish,
  fr: french,
  nl: dutch,
  it: italian,
};

type LocaleName = keyof typeof resources;
const supportedLocales = new Set(Object.keys(resources));
const getLanguageCode = (locale: LocaleName) => first(locale.split("-"));

const deviceLocale: LocaleName = (() => {
  const locales =
    navigator?.languages ??
    (navigator?.language != null ? [navigator.language] : []);

  for (const locale of locales) {
    if (supportedLocales.has(locale)) {
      return locale as LocaleName;
    } else {
      const language = getLanguageCode(locale as LocaleName);
      if (language != null && supportedLocales.has(language)) {
        return language as LocaleName;
      }
    }
  }

  return "en";
})();

// Augment the return type of react-i18next so that i18n.language returns a `LocaleName` rather than `string`
declare module "react-i18next" {
  type NamespaceOptions<N extends Namespace> = Localisation[N];
  export function useTranslation<
    Ns extends Namespace,
    KPrefix extends KeyPrefix<Ns> = undefined,
  >(
    ns: Ns,
    options?: UseTranslationOptions<KPrefix>,
  ): Omit<UseTranslationResponse<Ns, KPrefix>, "t"> & {
    t: (key: keyof NamespaceOptions<Ns>, args?: any) => string;
  };
}

export const initLocalisation = (): void => {
  i18n.use(initReactI18next).init({
    debug: config.isDevelopment,
    resources,
    lng: deviceLocale,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
};
